import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { login, changePassword } from 'redux/auth/actions';
import { isLoginError, errorMessage, isLoggedIn, isSuccess, getStatusText } from 'redux/auth/selectors';

import LoginForm from 'views/LoginForm'
import { Form } from './styled';

const mapStateToProps = state => {
  return {
    isError: isLoginError(state),
    error: errorMessage(state),
    isLoggedIn: isLoggedIn(state),
    isSuccess: isSuccess(state),
    statusText: getStatusText(state)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    login: (user) => {
      dispatch(login(user));
    },
    resetPass: (email) => {
      dispatch(changePassword(email));
    }
  }
}

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: null,
      password: null,
      isReset: false
    };
  }

  handleLogin(event) {
    event.preventDefault();
    
    this.props.login({
      email: this.state.email,
      password: this.state.password 
    })
  }

  handleReset(event) {
    event.preventDefault();

    this.props.resetPass(this.state.email);
  }

  handleEmailChange(event) {
    this.setState({
      email: event.target.value
    })
  }

  handlePasswordChange(event) {
    this.setState({
      password: event.target.value
    })
  }

  toggleReset(event) {
    this.setState((state) => {
      return { isReset: !state.isReset };
    });
  }

  render() {
    const formProps = {
      onLogin: this.handleLogin.bind(this),
      isError: this.props.isError,
      error: this.props.error,
      onEmail: this.handleEmailChange.bind(this),
      onPassword: this.handlePasswordChange.bind(this),
      email: this.state.value,
      password: this.state.value,
      toggleReset: this.toggleReset.bind(this),
      onReset: this.handleReset.bind(this),
      isReset: this.state.isReset,
      isSuccess: this.props.isSuccess,
      statusText: this.props.statusText
    }

    return(
      <div>
        <Form>
          {!this.props.isLoggedIn ? <LoginForm {...formProps} /> : <Redirect to="/" />}
        </Form>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);