const sizes = {
  mobile: {
    min: 320,
    max: 480
  },
  mobile_l: {
    min: 481,
    max: 767
  },
  tablet: {
    min: 768,
    max: 1024
  },
  tablet_l: {
    min: 768,
    max: 1024
  },
  laptop: {
    min: 1025,
    max: 1280
  },
  desktop: {
    min: 1281
  }
}

const toRem = (px) => {
  return px / 16;
}

export const media = {
  mobile: `(min-width: ${toRem(sizes.mobile.min)}rem)`,
  mobileLandscape: `(min-width: ${toRem(sizes.mobile_l.min)}rem)`,
  tablet: `(min-width: ${toRem(sizes.tablet.min)}rem)`,
  tabletLandscape: `(min-width: ${toRem(sizes.tablet_l.min)}rem)`,
  laptop: `(min-width: ${toRem(sizes.laptop.min)}rem)`,
  desktop: `(min-width: ${toRem(sizes.desktop.min)}rem)`
}