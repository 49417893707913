import {
  CHANGE_WEEK,
  GET_RESULT,
  GET_RESULT_FAIL,
  GET_RESULT_OK,
  GET_RESULT_EDIT_OK,
  GET_WEEK_RANGES,
  GET_WEEK_RANGES_FAIL,
  GET_WEEK_RANGES_OK,
  UPDATE_SCORE,
  ADD_RESULT_OK,
  UPDATE_WINNERS,
  UPDATE_SORT,
  DELETE_ROUND_OK,
  GET_LEADERBOARD,
  GET_LEADERBOARD_FAIL,
  GET_LEADERBOARD_OK
} from './actions';

const initialState = {
  results: {},
  playersById: [],
  leadersById: [],
  leaders: {},
  currentWeek: null,
  startWeek: null,
  endWeek: null,
  sort: 'score',
  _isError: false,
  _isFetching: true,
  _isFetchingRanges: true,
  _isFetchingPlayers: false,
  _isFetchingLeaders: false,
  _isSuccess: false,
  _statusText: ''
}

function results(state = initialState, action) {
  switch (action.type) {
    case UPDATE_SORT:
      return {
        ...state,
        sort: action.sort
      }
    case UPDATE_WINNERS:
      return {
        ...state,
        results: {
          ...state.results,
          [action.week]: {
            ...state.results[action.week],
            winners: action.winners
          }
        }
      }
    case UPDATE_SCORE: 
      return {
        ...state,
        results: {
          ...state.results,
          [state.currentWeek]: {
            ...state.results[state.currentWeek],
            players: {
              ...state.results[state.currentWeek].players,
              [action.id]: {
                ...state.results[state.currentWeek].players[action.id],
                scores: {
                  ...state.results[state.currentWeek].players[action.id].scores,
                  [action.hole]: {
                    ...state.results[state.currentWeek].players[action.id].scores[action.hole],
                    strokes: action.value,
                    hole_id: action.hole_id
                  }
                },
                grossIn: action.in,
                grossOut: action.out,
                grossTotal: action.tot,
                netTotal: action.netTot,
                new_hcp: action.new_hcp
              }
            }
          }
        }
      }
    case DELETE_ROUND_OK: 
      return {
        ...state,
        results: {
          ...state.results,
          [action.week]: {
            ...state.results[action.week],
            players: {
              ...state.results[action.week].players,
              [action.id]: {
                ...state.results[action.week].players[action.id],
                ...action.data
              }
            }
          }
        }
      }
    case GET_RESULT:
      return {
        ...state,
        _isFetching: true
      }
    case GET_RESULT_OK:
      return {
        ...state,
        results: {
          ...state.results,
          [action.nr]: {
            course: action.res.course,
            nr: action.res.nr,
            tee: action.res.tee,
            _id: action.res._id,
            players: action.res.players,
            winners: action.res.winners
          }
        },
        currentWeek: action.nr,
        playersById: Object.keys(action.res.players),
        _isFetching: false,
        _isError: false,
        _isSuccess: true
      }
    case GET_RESULT_EDIT_OK:
        return {
          ...state,
          results: {
            ...state.results,
            [action.nr]: {
              course: action.res.course,
              nr: action.res.nr,
              tee: action.res.tee,
              _id: action.res._id,
              players: {
                ...action.res.players
              },
              winners: action.res.winners
            }
          },
          playersById: Object.keys(action.res.players),
          currentWeek: action.nr,
          _isFetching: false,
          _isError: false,
          _isSuccess: true
        }
    case ADD_RESULT_OK:
      return {
        ...state,
        results: {
          ...state.results,
          [action.res.nr]: {
            ...state.results[action.res.nr],
            players: {
              ...state.results[action.res.nr].players,
              ...action.res.players
            },
            winners: {
              ...action.res.winners
            }
          }
        },
        _isSuccess: true,
        _statusText: 'Resultat sparat'
      }
    case GET_RESULT_FAIL:
      return {
        ...state,
        _isFetching: false,
        _isError: true,
        _isSuccess: false
      }
    case CHANGE_WEEK:
      return {
        ...state,
        currentWeek: action.nr,
        playersById: Object.keys(state.results[action.nr].players)
      }
    case GET_WEEK_RANGES: 
      return {
        ...state,
        _isFetchingRanges: true
      }
    case GET_WEEK_RANGES_OK:
      return {
        ...state,
        startWeek: action.start,
        endWeek: action.end,
        lastWeek: action.latestWeek,
        _isFetchingRanges: false
      }
    case GET_WEEK_RANGES_FAIL: 
      return {
        ...state,
        _isFetchingRanges: false
      }
    case GET_LEADERBOARD:
      return {
        ...state,
        _isError: false,
        _isFetchingLeaders: true
      }
    case GET_LEADERBOARD_OK: {
      return {
        ...state,
        _isError: false,
        leaders: {
          ...action.players
        },
        leadersById: Object.keys(action.players),
        _isFetchingLeaders: false
      }
    }
    case GET_LEADERBOARD_FAIL: {
      return {
        ...state,
        _isError: true,
        _isFetchingLeaders: false
      }
    }
    default: 
      return state;
  }
}

export default results;