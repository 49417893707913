import React from 'react';
import styled, { css } from 'styled-components';
import { KeyboardArrowLeft, KeyboardArrowRight } from 'styled-icons/material';
import { styledH6, mdSubtitle2 } from 'styles/TextStyles';
import Spinner from 'components/spinner/spinner';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: auto;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 auto;
`;

const WeekTitle = styled(styledH6)`
  margin-top: 0;
  margin-bottom: 0;
  color: ${props => props.theme.primaryHeaderText}; 
`;

const WeekSubtitle = styled(mdSubtitle2)`
  margin-top: 0;
  margin-bottom: 0;
  color: ${props => props.theme.secondaryText}; 
`;

const StyledButton = styled.button`
  flex: 0 1 auto;
  height: 100%;
  width: 60px;
  padding: 0;
  border: none;
  background: transparent;
  outline: none;
  color: ${props => props.theme.primaryLight};

  display: flex;
  justify-content: center;

  svg {
    height: 100%;
    width: 40px;
  }

  &:active {
    color: ${props => props.theme.secondary};
  }

  ${props => props.hide && css`
    visibility: hidden;
    pointer-events: none;
  `}
`;

const WeekCarousel = (props) => (
  <Container>
    <StyledButton hide={props.hidePrev} onClick={props.prevWeek}>
      <KeyboardArrowLeft />
    </StyledButton>
    <TextContainer>
      {!props.isLoading ? 
        <React.Fragment>
          <WeekTitle>Vecka { props.weekNr }</WeekTitle>
          <WeekSubtitle>{ props.courseName } - Tee: { props.tee }</WeekSubtitle>
        </React.Fragment> :
        <React.Fragment>
          <Spinner />
        </React.Fragment>
      }
    </TextContainer>
    <StyledButton hide={props.hideNext} onClick={props.nextWeek}>
      <KeyboardArrowRight />
    </StyledButton>
  </Container>
);

export default WeekCarousel;