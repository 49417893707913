import { 
  ADD_COURSE, 
  ADD_COURSE_SUCCESS, 
  ADD_COURSE_FAILED,
  UPDATE_NEW_COURSE,
  UPDATE_NEW_COURSE_HOLE,
  UPDATE_NEW_COURSE_TEE,
  GET_COURSES,
  GET_COURSES_FAIL,
  GET_COURSES_OK
} from './actions';

const initialState = {
  courses: {},
  coursesById: [],
  isFetchingCourses: true,
  isCoursesError: false,
  isCoursesOk: false
}

function courses(state = initialState, action) {
  switch (action.type) {
    case GET_COURSES:
      return {
        ...state,
        isFetchingCourses: true,
        isCoursesOk: false,
        isCoursesError: false
      }
    case GET_COURSES_OK:
      return {
        ...state,
        courses: {
          ...action.courses
        },
        coursesById: Object.keys(action.courses),
        isFetchingCourses: false,
        isCoursesError: false,
        isCoursesOk: true
      }
    case GET_COURSES_FAIL:
      return {
        ...state,
        isFetchingCourses: false,
        isCoursesError: true,
        isCoursesOk: false
      }
    case ADD_COURSE:
      return Object.assign({}, state, {
        _isLoading: true,
        _isError: false
      });
    case ADD_COURSE_SUCCESS:
      return Object.assign({}, state, {
        message: action.message,
        _isLoading: false,
        _isError: false
      })
    case ADD_COURSE_FAILED:
      return Object.assign({}, state, {
        message: action.message,
        _isLoading: false,
        _isError: true
      });
    case UPDATE_NEW_COURSE:
      return {
        ...state,
        course: {
          ...state.course,
          ...action.data
        }
      }
    case UPDATE_NEW_COURSE_HOLE:
      return {
        ...state,
        course: {
          ...state.course,
          holes: {
            ...state.course.holes,
            ...action.data
          }
        }
      }
    case UPDATE_NEW_COURSE_TEE:
      return {
        ...state,
        course: {
          ...state.course,
          holes: {
            ...state.course.holes
          },
          tees: {
            ...state.course.tees,
            ...action.data
          }
        }
      }
    default:
      return state;
  }
}

export default courses;