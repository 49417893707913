import apiService from 'services/api';

export const NEW_USER = "new_user";
export const UPDATE_USER = "update_user";
export const ADD_USERS = "add_users";
export const ADD_USERS_FAIL = "add_users_fail";
export const ADD_USERS_OK = "add_users_ok";
export const GET_USERS = "get_users";
export const GET_USERS_OK = "get_users_ok";
export const GET_USERS_FAIL = "get_users_fail";
export const DELETE_USER = "delete_user";
export const DELETE_USER_OK = "delete_user_ok";
export const DELETE_USER_FAIL = "delete_user_fail";
export const GET_PROFILE = "get_profile";
export const GET_PROFILE_OK = "get_profile_ok";
export const GET_PROFILE_FAIL = "get_profile_fail";

export const newUser = (id) => ({
  type: NEW_USER,
  id: id
});

export const updateUser = (id, type, data) => ({
  type: UPDATE_USER,
  id: id,
  field: type,
  data: data
});

export const addUsers = () => (dispatch, getState) => {
  const users = getState().users.users;

  if (Object.entries(users).length !== 0) {
    dispatch({
      type: ADD_USERS
    });

    apiService.addPlayers({ users: users }).then(res => {
      dispatch({
        type: ADD_USERS_OK
      });
      dispatch({
        type: GET_USERS_OK,
        players: res.data.players
      })
    }).catch(err => {
      dispatch({
        type: ADD_USERS_FAIL
      });
    });
  } else {
    dispatch({
      type: ADD_USERS_FAIL
    });
  }
}

export const deleteUser = (id) => (dispatch, getState) => {
  const user = getState().users.users[id];

  dispatch({
    type: DELETE_USER
  });

  if (!user.id) {
    dispatch({
      type: DELETE_USER_OK,
      id: id
    })
  } else {
    apiService.deleteUser(id).then(res => {
      dispatch({
        type: DELETE_USER_OK,
        id: id
      })
    }).catch(err => {
      dispatch({
        type: DELETE_USER_FAIL
      });
    });
  }
}

export const getAllUsers = () => dispatch => {
  dispatch({
    type: GET_USERS
  });

  apiService.getUsers().then(res => {
    dispatch({
      type: GET_USERS_OK,
      players: res.data.players
    });
  }).catch(err => {
    dispatch({
      type: GET_USERS_FAIL
    });
  })
};

export const fetchProfile = () => (dispatch, getState) => {
  const lastFetched = getState().users._profileUpdated;
  const isStale = Math.abs(Math.round((Date.now() - lastFetched) / 36e5)) >= 2;

  if (isStale) {
    dispatch({
      type: GET_PROFILE
    });

    apiService.getProfile().then(res => {
      dispatch({
        type: GET_PROFILE_OK,
        player: res.data
      })
    }).catch(() => {
      dispatch({
        type: GET_PROFILE_FAIL
      })
    })
  }
}