import React from 'react';
import {
  DataRow,
  DataItem,
  DataHeader,
  DataText
} from './style';

const ProfileData = props => (
  <DataRow>
    <DataItem>
      <DataHeader>Rundor</DataHeader>
      <DataText>{props.profile.nr_of_rounds}</DataText>
    </DataItem>
    <DataItem>
      <DataHeader>Poäng</DataHeader>
      <DataText>{props.profile.points}</DataText>
    </DataItem>
    <DataItem>
      <DataHeader>HCP</DataHeader>
      <DataText>{props.profile.hcp}</DataText>
    </DataItem>
  </DataRow>
)

export default ProfileData;