import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Content from 'containers/Content/Content';

import { isRenewed, isRenewFail } from 'redux/auth/selectors';
import { renewTokenSuccess, renewTokenFailed } from 'redux/auth/actions';

const mapStateToProps = state => {
  return {
    isRenewed: isRenewed(state),
    isFailed: isRenewFail(state)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    renewSuccess: () => {
      dispatch(renewTokenSuccess());
    },
    renewFailed: () => {
      dispatch(renewTokenFailed());
    }
  }
}

class App extends Component {
  componentDidMount() {
    const { renewSession } = this.props.auth;

    renewSession().then(() => {
      this.props.renewSuccess();
    }).catch(err => {
      this.props.renewFailed();
    });
  }

  render() {
    if (this.props.isRenewed) {
      return <Content />
    }

    return null;
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(App));
