import React from 'react';
import styled from 'styled-components';
import { media } from 'styles/utils/media'; 

const NavbarLogo = styled.div`
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  align-items: center;
  order: 1;

  @media ${media.laptop} {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
`;

const StyledLogo = styled.img`
  height: 2rem;
  width: 9rem;
  margin-left: -4.5rem;
  margin-top: 0.25rem;

  @media ${media.laptop} {
    margin-left: 0;
  }
`;

const Logo = () => (
  <NavbarLogo>
    <StyledLogo src="Armadan8.svg" />
  </NavbarLogo>
);

export default Logo;

