import React from 'react';
import styled from 'styled-components';
import { mdBody2 } from 'styles/TextStyles';

const Footer = styled.div`
  display: flex;
`;

const FooterDate = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
`;

const Author = styled.div`
  flex: 1 1 auto;
`;

const StaticText = styled(mdBody2)`
  color: ${props => props.theme.secondaryText};
  margin-bottom: 0;
  margin-top: 0;
`;

const ArticleFooter = (props) => (
  <Footer>
    <Author>
      <StaticText>Inlagd av: {props.name}</StaticText>
    </Author>
    <FooterDate>
      <StaticText>
        {(new Date(props.date)).toLocaleDateString('sv-SE')}
      </StaticText>
    </FooterDate>
  </Footer>
)

export default ArticleFooter;