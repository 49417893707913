import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const StyledSpinner = styled.div`
  display: inline-block;
  position: relative;
  width: ${props => props.width || "51px"};
  height: ${props => props.height || "51px"};

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${props => props.width || "51px"};
    height: ${props => props.height || "51px"};
    border: 4px solid #fff;
    border-radius: 50%;
    animation: ${rotate} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${props => props.color || props.theme.primary} transparent transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;

const Spinner = (props) => (
  <StyledSpinner { ...props} >
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </StyledSpinner>
)

export default Spinner;