import apiService from "services/api";
import { getResults, getPlayed, currentWeek } from "./selectors";
import { courses } from "redux/courses/selectors";
import {
  getStrokes,
  getGrossScores,
  getNewHcp,
  getPoints,
} from "utils/hcpUtils";

export const CHANGE_WEEK = "change_week";
export const GET_RESULT = "get_result";
export const GET_RESULT_EDIT_OK = "get_result_edit_ok";
export const GET_RESULT_OK = "get_result_ok";
export const GET_RESULT_FAIL = "get_result_fail";
export const GET_WEEK_RANGES = "get_week_ranges";
export const GET_WEEK_RANGES_OK = "get_week_ranges_ok";
export const GET_WEEK_RANGES_FAIL = "get_week_ranges_fail";
export const GET_PLAYERS = "get_players";
export const GET_PLAYERS_OK = "get_players_ok";
export const GET_PLAYERS_FAIL = "get_players_fail";
export const UPDATE_SCORE = "update_score";
export const ADD_RESULT_OK = "add_result_ok";
export const UPDATE_WINNERS = "update_winners";
export const UPDATE_SORT = "update_sort";
export const DELETE_ROUND_OK = "delete_round_ok";
export const GET_LEADERBOARD = "get_leaderboard";
export const GET_LEADERBOARD_OK = "get_leaderboard_ok";
export const GET_LEADERBOARD_FAIL = "get_leaderboard_fail";

const getEmptyScores = () => {
  var ret = {
    scores: {},
    netTotal: 0,
    grossIn: 0,
    grossOut: 0,
    grossTotal: 0,
  };

  for (var i = 1; i <= 18; ++i) {
    ret.scores[i] = {
      strokes: null,
    };
  }

  return ret;
};

export const updateScore = (id, hole, value) => (dispatch, getState) => {
  const results = getResults(getState()),
    course = courses(getState())[results.course],
    tee = course.tees[results.tee],
    player = results.players[id];

  player.scores[hole].strokes = value;

  var strokes = Math.min(
      getStrokes(player.hcp, tee.cr, tee.slope, course.par),
      18
    ),
    gross = getGrossScores(player),
    netTot = Math.max(0, gross.tot - strokes),
    new_hcp;

  if (netTot > 0) {
    new_hcp = getNewHcp(player.hcp, course.par, netTot, strokes);
  } else {
    new_hcp = player.hcp;
  }

  dispatch({
    type: UPDATE_SCORE,
    id: id,
    hole: hole,
    value: value,
    hole_id: course.holes[hole]._id,
    netTot: netTot,
    new_hcp: new_hcp,
    ...gross,
  });
};

export const getResult = (nr, isEdit, force) => (dispatch, getState) => {
  var rangePromise;

  if (getState().results.startWeek && getState().results.endWeek) {
    rangePromise = Promise.resolve({ data: null });
  } else {
    rangePromise = apiService.getWeekRanges();
  }

  rangePromise.then((response) => {
    var weekNr = nr;

    if (response.data) {
      dispatch({
        type: GET_WEEK_RANGES_OK,
        ...response.data,
      });

      weekNr = isEdit
        ? Math.min(response.data.latestWeek + 1, response.data.end)
        : response.data.latestWeek;
    }

    if (isEdit) {
      var pPromise = apiService.getPlayers(),
        rPromise = apiService.getResults(weekNr);

      dispatch({
        type: GET_RESULT,
      });

      Promise.all([pPromise, rPromise])
        .then((res) => {
          let players = res[0].data,
            results = res[1].data,
            tmp;

          for (var p in players) {
            players[p] = { ...players[p], ...getEmptyScores() };
          }

          tmp = {
            ...results,
            players: {
              ...players,
              ...results.players,
            },
          };

          dispatch({
            type: GET_RESULT_EDIT_OK,
            nr: weekNr,
            res: tmp,
          });
        })
        .catch((err) => {
          console.log(err);
          dispatch({
            type: GET_RESULT_FAIL,
          });
        });
    } else {
      var results = getState().results.results;

      if (!force && results.hasOwnProperty(weekNr)) {
        return dispatch({
          type: CHANGE_WEEK,
          nr: nr,
        });
      }

      dispatch({
        type: GET_RESULT,
      });

      apiService
        .getResults(weekNr)
        .then((res) => {
          dispatch({
            type: GET_RESULT_OK,
            nr: weekNr,
            res: { ...res.data },
          });
        })
        .catch((err) => {
          dispatch({
            type: GET_RESULT_FAIL,
          });
        });
    }
  });
};

export const getRanges = () => (dispatch) => {
  dispatch({
    type: GET_WEEK_RANGES,
  });

  apiService
    .getWeekRanges()
    .then((res) => {
      dispatch({
        type: GET_WEEK_RANGES_OK,
        ...res.data,
      });
    })
    .catch(() => {
      dispatch({
        type: GET_WEEK_RANGES_FAIL,
      });
    });
};

export const getWinners = () => (dispatch, getState) => {
  const played = getPlayed(getState()),
    players = { ...getResults(getState()).players },
    winners = { ...getResults(getState()).winners },
    points = getPoints(players, played),
    week = currentWeek(getState());

  Object.keys(winners).map((id) => {
    winners[id] = {
      ...winners[id],
      amount: 0,
    };

    return null;
  });

  Object.keys(points).map((id) => {
    winners[id] = {
      ...winners[id],
      ...points[id],
    };

    return null;
  });

  dispatch({
    type: UPDATE_WINNERS,
    winners: winners,
    week: week,
  });
};

export const addResult = () => (dispatch, getState) => {
  const results = { ...getResults(getState()) },
    week = getState().results.currentWeek,
    played = getPlayed(getState()),
    players = {};

  played.map((id) => {
    players[id] = results.players[id];

    return null;
  });

  results.players = players;

  apiService
    .addResult(week, results)
    .then((res) => {
      dispatch({
        type: ADD_RESULT_OK,
        res: res.data,
      });
    })
    .catch((err) => {
      console.log("ERROR");
    });
};

export const sortResults = (sort) => (dispatch) => {
  dispatch({
    type: UPDATE_SORT,
    sort: sort,
  });
};

export const deleteRound = (id) => (dispatch, getState) => {
  const player = getResults(getState()).players[id],
    week = currentWeek(getState());

  if (!player.hasOwnProperty("round_id")) {
    dispatch({
      type: DELETE_ROUND_OK,
      id: id,
      week: week,
      data: getEmptyScores(),
    });
  } else {
    apiService.deleteRound(player.round_id).then((res) => {
      dispatch({
        type: DELETE_ROUND_OK,
        id: id,
        week: week,
        data: getEmptyScores(),
      });
    });
  }
};

export const getLeaderboard = () => (dispatch) => {
  dispatch({
    type: GET_LEADERBOARD,
  });

  apiService
    .getLeaderboard()
    .then((res) => {
      dispatch({
        type: GET_LEADERBOARD_OK,
        players: { ...res.data },
      });
    })
    .catch(() => {
      dispatch({
        type: GET_LEADERBOARD_FAIL,
      });
    });
};
