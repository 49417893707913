import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const store = createStore(
  rootReducer,
  process.env.NODE_ENV === 'production' ? 
  applyMiddleware(thunk) :
  composeWithDevTools(
    applyMiddleware(thunk)
  )
)

export default store;