import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container } from './styled';
import ProfileData from 'components/profile';

import { fetchProfile } from 'redux/users/actions';
import { profile, fetchingProfile } from 'redux/users/selectors';

const mapStateToProps = state => ({
  profile: profile(state),
  isFetching: fetchingProfile(state)
});

const mapDispatchToProps = dispatch => ({
  fetchProfile: () => {
    dispatch(fetchProfile());
  }
})

class Profile extends Component {
  componentDidMount() {
    this.props.fetchProfile();
  }

  render() {
    return (
      <Container>
        { !this.props.isFetching && this.props.profile ? <ProfileData profile={this.props.profile} /> : "" }
      </Container>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile);