import {
  GET_COURSES,
  GET_COURSES_OK,
  GET_COURSES_FAIL,
  GET_SCHEDULE,
  GET_SCHEDULE_OK,
  GET_SCHDULE_FAIL,
  NEW_WEEK,
  UPDATE_WEEK,
  REMOVE_WEEK,
  REMOVE_WEEK_OK,
  REMOVE_WEEK_FAIL,
  ADD_SCHEDULE,
  ADD_SCHEDULE_OK,
  ADD_SCHEDULE_FAIL
} from './actions'

const initialState = {
  courses: {},
  coursesById: [],
  tees: {},
  teesById: [],
  weeks: {},
  weekById: [],
  _nextWeekId: 1,
  _isFetchingCourses: false,
  _isFetchingSchedule: false,
  _isError: false,
  _isSuccess: false,
  _isLoading: false,
  _statusText: "",
  _lastUpdated: 0
};

let highestWeek = 0;

function courses(state = initialState, action) {
  switch (action.type) {
    case NEW_WEEK:
      highestWeek += 1;
      let tees = Object.keys(state.courses[state.coursesById[0]].tees); 

      return {
        ...state,
        _nextWeekId: state._nextWeekId + 1,
        weekById: [ ...state.weekById, action.id ],
        weeks: {
          ...state.weeks,
          [action.id]: {
            _id: null,
            nr: highestWeek,
            course: state.coursesById[0],
            tee: state.courses[state.coursesById[0]].tees[tees[0]]._id
          }
        }
      };
    case GET_COURSES:
      return {
        ...state,
        _isFetchingCourses: true,
        _isError: false
      };
    case GET_COURSES_OK: 
      return {
        ...state,
        courses: action.courses,
        coursesById: Object.keys(action.courses),
        teesById: Object.keys(action.tees),
        tees: action.tees,
        _isFetchingCourses: false,
        _isError: false
      };
    case GET_SCHDULE_FAIL:
    case GET_COURSES_FAIL:
      return {
        ...state,
        _isFetchingSchedule: false,
        _isFetchingCourses: false,
        _isError: true,
        _statusText: "Kunde inte hämta schema"
      };
    case GET_SCHEDULE: 
      return {
        ...state,
        _isFetching: true,
        _isError: false,
        _isFetchingSchedule: true,
      }
    case GET_SCHEDULE_OK:
      highestWeek = action.data.highestWeek;
      return {
        ...state,
        ...action.data,
        weekById: Object.keys(action.data.weeks),
        _isFetchingSchedule: false,
        _isError: false,
        _lastUpdated: Date.now()
      }
    case UPDATE_WEEK:
      if (action.field === 'nr') {
        highestWeek = (action.data > highestWeek ? action.data : highestWeek);
      }

      return {
        ...state,
        weeks: {
          ...state.weeks,
          [action.id]: {
            ...state.weeks[action.id],
            [action.field]: action.data
          }
        }
      }
    case REMOVE_WEEK_OK:
      const { [action.id]: deletedValue, ...newState_week } = state.weeks;

      return {
        ...state,
        weekById: state.weekById.filter(item => item !== action.id),
        weeks: newState_week,
        _isSuccess: true,
        _isError: false,
        _isLoading: false,
        _statusText: "Vecka borttagen"
      };
    case REMOVE_WEEK_FAIL:
      return {
        ...state,
        _isLoading: false,
        _isError: true,
        _isSuccess: false,
        _statusText: "Kunde inte ta bort vecka"
      }
    case REMOVE_WEEK:
    case ADD_SCHEDULE:
      return {
        ...state,
        _isLoading: true,
        _isError: false,
        _isSuccess: false,
        _statusText: ""
      }
    case ADD_SCHEDULE_OK:
      highestWeek = action.data.highestWeek;

      return {
        ...state,
        ...action.data,
        weekById: Object.keys(action.data.weeks),
        _isLoading: false,
        _isError: false,
        _isSuccess: true,
        _lastUpdated: Date.now(),
        _statusText: "Schema uppdaterat"
      }
    case ADD_SCHEDULE_FAIL:
      return {
        ...state,
        _isLoading: false,
        _isError: true,
        _isSuccess: false,
        _statusText: "Kunde inte uppdatera schema"
      }
    default:
      return state;
  }
}

export default courses;