export function getISODay(date) {
	return (date.getDay() + 6) % 7; 
}

export function getWeekDates(nr) {
	var tmp = new Date(new Date().getFullYear(), 0, 4);
  var firstWeekDay = new Date(new Date().getFullYear(), 0, 4);
  var lastWeekDay;
  
  var d = (getISODay(tmp));

  firstWeekDay.setDate(firstWeekDay.getDate() - d + 1);
  
  lastWeekDay = new Date(firstWeekDay);
  lastWeekDay.setDate(lastWeekDay.getDate() + 5);
  
  firstWeekDay.setDate(firstWeekDay.getDate() + (7 * (nr - 1)));
  lastWeekDay.setDate(lastWeekDay.getDate() + (7 * (nr - 1)));
  
  return { 
    first: firstWeekDay,
    last: lastWeekDay
  };
}

export function getWeekDatesStr(nr) {
  if (!nr) {
    return '';
  }

  var dates = getWeekDates(nr);

  return `${formatWeekDate(dates.first)} - ${formatWeekDate(dates.last)}`;
}

function formatWeekDate(date) {
  return `${date.getDate()}/${date.getMonth() + 1}`
}

export function getCurrentWeek() {
  var today = new Date();
  var week;

  today.setHours(0,0,0,0);

  for(var i = 1; i <= 52; ++i) {
    week = getWeekDates(i);
    if (week.first <= today && today <= week.last) {
      return i;
    }
  }
}