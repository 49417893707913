export const getStrokes = (hcp, cr, slope, par) => {
  return Math.round(hcp * (slope / 113) + (cr - par));
};

export const getGrossScores = (player) => {
  var grossIn = 0,
    grossOut = 0;

  for (var i = 1; i <= 18; ++i) {
    if (player.scores[i].strokes) {
      if (i < 10) {
        grossOut += player.scores[i].strokes;
      } else {
        grossIn += player.scores[i].strokes;
      }
    }
  }

  return {
    out: grossOut,
    in: grossIn,
    tot: grossOut + grossIn,
  };
};

export const getScoreColor = (score, par) => {
  if (!score) {
    return "even";
  }

  if (score > par) {
    return "overPar";
  } else if (score < par) {
    return "underPar";
  }

  return "even";
};

const getHcpGroup = (hcp) => {
  if (hcp <= 4.4) {
    return 0.1;
  } else if (hcp >= 4.5 && hcp <= 11.4) {
    return 0.2;
  } else if (hcp >= 11.5 && hcp <= 18.4) {
    return 0.3;
  } else if (hcp >= 18.5 && hcp <= 26.4) {
    return 0.4;
  } else if (hcp >= 26.5 && hcp <= 36) {
    return 0.5;
  }
};

export const getNewHcp = (hcp, par, total, buffer) => {
  if (total < par) {
    return Math.round((hcp - (par - total) * getHcpGroup(hcp)) * 10) / 10;
  } else if (total > par + getHcpGroup(hcp) * 10) {
    return Math.round((hcp + 0.1) * 10) / 10;
  } else {
    return hcp;
  }
};

const getPricePool = (players) => {
  return 50 * players;
};

const getPlacements = (players, ids) => {
  var placements = [];

  placements.push(ids[0]);

  for (var i = 1; i < ids.length; ++i) {
    if (players[ids[i]].netTotal === players[ids[0]].netTotal) {
      placements.push(ids[i]);
    }
  }

  return placements;
};

const getNrOfWinners = (players) => {
  if (players <= 10) {
    return 1;
  } else if (players <= 20) {
    return 2;
  } else if (players <= 30) {
    return 3;
  }

  return 4;
};

const formatWinners = (winners, points) => {
  var ret = {};

  for (var i = 0; i < winners.length; ++i) {
    ret[winners[i]] = {
      amount: points,
    };
  }

  return ret;
};

export const getPoints = (players, ids) => {
  var nr = getNrOfWinners(ids.length),
    pool = getPricePool(ids.length),
    first = getPlacements(players, ids),
    second = getPlacements(players, ids.slice(first.length)),
    third = getPlacements(players, ids.slice(first.length + second.length)),
    fourth = getPlacements(
      players,
      ids.slice(first.length + second.length + third.length)
    );

  if (
    nr === 1 ||
    (nr === 2 && first.length >= 2) ||
    (nr === 3 && first.length >= 3) ||
    (nr === 4 && first.length >= 4)
  ) {
    return formatWinners(first, Math.round(pool / first.length));
  } else if (nr === 2) {
    return {
      ...formatWinners(first, Math.round(pool * 0.6)),
      ...formatWinners(second, Math.round((pool * 0.4) / second.length)),
    };
  } else if (nr === 3) {
    if (first.length === 2) {
      return {
        ...formatWinners(first, Math.round((pool * 0.8) / first.length)),
        ...formatWinners(second, Math.round((pool * 0.2) / second.length)),
      };
    } else if (first.length === 1 && second.length >= 2) {
      return {
        ...formatWinners(first, Math.round((pool * 0.5) / first.length)),
        ...formatWinners(second, Math.round((pool * 0.5) / second.length)),
      };
    } else {
      return {
        ...formatWinners(first, Math.round(pool * 0.5)),
        ...formatWinners(second, Math.round(pool * 0.3)),
        ...formatWinners(third, Math.round((pool * 0.2) / third.length)),
      };
    }
  } else if (nr === 4) {
    if (first.length === 3) {
      return {
        ...formatWinners(first, Math.round((pool * 0.9) / first.length)),
        ...formatWinners(second, Math.round((pool * 0.1) / second.length)),
      };
    } else if (first.length === 2 && second.length >= 2) {
      return {
        ...formatWinners(first, Math.round((pool * 0.75) / first.length)),
        ...formatWinners(second, Math.round((pool * 0.25) / second.length)),
      };
    } else if (first.length === 2 && second.length === 1) {
      return {
        ...formatWinners(first, Math.round((pool * 0.75) / first.length)),
        ...formatWinners(second, Math.round(pool * 0.15)),
        ...formatWinners(third, Math.round((pool * 0.1) / third.length)),
      };
    } else if (first.length === 1 && second.length >= 3) {
      return {
        ...formatWinners(first, Math.round(pool * 0.5)),
        ...formatWinners(second, Math.round((pool * 0.5) / second.length)),
      };
    } else if (first.length === 1 && second.length === 2) {
      return {
        ...formatWinners(first, Math.round(pool * 0.5)),
        ...formatWinners(second, Math.round((pool * 0.4) / second.length)),
        ...formatWinners(third, Math.round((pool * 0.1) / third.length)),
      };
    } else if (first.length === 1 && second.length === 1 && third.length >= 2) {
      return {
        ...formatWinners(first, Math.round(pool * 0.5)),
        ...formatWinners(second, Math.round(pool * 0.25)),
        ...formatWinners(third, Math.round((pool * 0.25) / third.length)),
      };
    } else {
      return {
        ...formatWinners(first, Math.round(pool * 0.5)),
        ...formatWinners(second, Math.round(pool * 0.25)),
        ...formatWinners(third, Math.round(pool * 0.15)),
        ...formatWinners(fourth, Math.round((pool * 0.1) / fourth.length)),
      };
    }
  }
};
