import { Auth0Client } from '@auth0/auth0-spa-js';

const REDIRECT_URI = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : "");
const DB_CONN = process.env.REACT_APP_AUTH0_DB;

class Auth {
  expiresAt;
  idToken;
  renewTimout;

  constructor() {
    this.auth = new Auth0Client({
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
      redirect_uri: REDIRECT_URI + '/auth',
      audience: 'https://armadan.se/api',
      scope: 'openid profile email',
      useRefreshTokens: true,
      cacheLocation: 'localstorage'
    });

    this.getAccessToken = this.getAccessToken.bind(this);
    this.setSession = this.setSession.bind(this);
    this.renewSession = this.renewSession.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.isAdmin = this.isAdmin.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
  }

  getAccessToken() {
    return this.accessToken;
  }

  getIdToken() {
    return this.idToken;
  }

  isAuthenticated() {
    return !!this.accessToken;
  }

  isAdmin() {
    return this.admin;
  }

  async login() {
    await this.auth.loginWithRedirect();
  }

  async setSession(token) {
    localStorage.setItem('is_logged_in', 'true');

    const user = await this.auth.getUser();
    this.accessToken = token;
    this.admin = user["https://armadan.se/roles"].roles.length !== 0;
  }

  async renewSession() {
    try {
      const token = await this.auth.getTokenSilently({
        audience: 'https://armadan.se/api',
        scope: 'openid profile email'
      });
      await this.setSession(token);
    } catch (error) {
      if (error.error !== 'login_required') {
        throw error;
      }
    }
  }

  async handleAuthentication() {
    await this.auth.handleRedirectCallback();
    await this.setSession(await this.auth.getTokenSilently());
  }

  resetSession() {
    this.accessToken = null;
    this.idToken = null;
    this.admin = false;

    localStorage.removeItem('is_logged_in');
  }

  changePassword(email) {
    return new Promise((resolve, reject) => {
      this.auth.changePassword({
        connection: DB_CONN,
        email: email
      }, (err, res) => {
        if (err) {
          return reject(err);
        }

        resolve();
      })
    });
  }

  logout() {
    this.resetSession();

    this.auth.logout({
      returnTo: REDIRECT_URI + '/login'
    });
  }
}

const authService = new Auth();

export default authService;