import styled from 'styled-components';
import { media } from 'styles/utils/media';

export const TileListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  justify-content: center;
  
  @media ${media.laptop} {
    flex-direction: row;
    justify-content: flex-start;
  }
`;

export const TileContainer = styled.div`
  width: 100%;
  background: ${props => props.theme.appWhite};
  margin-bottom: 0.4rem;
`;