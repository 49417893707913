import React from 'react';
import { 
  LeaderListContainer, 
  LeaderListHeader, 
  LeaderListItem, 
  LeaderListItemColumn 
} from './style';

const LeaderList = props => (
  <LeaderListContainer>
    <LeaderListHeader>
      <LeaderListItemColumn>Spelare</LeaderListItemColumn>
      <LeaderListItemColumn>Rundor</LeaderListItemColumn>
      <LeaderListItemColumn>Poäng</LeaderListItemColumn>
    </LeaderListHeader>
    { props.leaderIds.map(id => {
      let player = props.leaders[id];

      return (
        <LeaderListItem key={id}>
          <LeaderListItemColumn>{`${player.firstname} ${player.lastname}`}</LeaderListItemColumn>
          <LeaderListItemColumn>{`${player.nr_of_rounds}`}</LeaderListItemColumn>
          <LeaderListItemColumn>{`${player.points}`}</LeaderListItemColumn>
        </LeaderListItem>
      )
    })}
    
  </LeaderListContainer>
)

export default LeaderList;
