import styled from 'styled-components';
import { mdBody } from 'styles/TextStyles';

export const LeaderListContainer = styled.div`
  width: 100%;
  background: ${props => props.theme.appWhite};
  margin-bottom: 0.4rem;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const LeaderListItem = styled.div`
  display: flex;
  flex: 1 0 auto;
  color: ${props => props.theme.primaryText};
  border-bottom: 0.05rem solid ${props => props.theme.primaryLightest};

  &:last-child {
    border: none;
  }
`;

export const LeaderListHeader = styled(LeaderListItem)`
  background: ${props => props.theme.primaryDark};
  color: ${props => props.theme.primaryLightest};
  border: none;
`;

export const LeaderListItemColumn = styled(mdBody)`
  margin: 0;
  flex: 0 1 3.75rem;
  padding: 0.5rem;
  text-align: center;
  border-left: 0.05rem solid ${props => props.theme.primaryLightest};

  &:first-child {
    flex: 1 0 auto;
    text-align: left;
    border: none;
  }

  &:last-child {
    flex: 0 1 3.125rem;
  }
`;