import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.6rem;
  flex: 1 0 auto;
`;

const Label = styled.span`
  font-family: Work Sans, sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: ${props => props.theme.primaryHeaderText};
  margin-bottom: 0.1rem;
`;

const Input = styled.input`
  font-family: Work Sans, sans-serif;
  border: 1px solid lightgray;
  border-radius: 0.2rem;
  padding: 0.3rem 0.5rem;
  font-size: 1rem;
  outline: none;
  color: ${props => props.theme.primaryText};
`;

const InputWithLabel = (props) => (
  <Container>
    <Label>{props.label}</Label>
    <Input 
      type={props.type}
      onChange={props.onChange}
      placeholder={props.placeholder}
      value={props.value}
    />
  </Container>
)

export default InputWithLabel;