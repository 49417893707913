import React from 'react';
import styled, { css } from 'styled-components';
import { media } from 'styles/utils/media';
 
const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 4.5rem;
  justify-content: center;
  align-items: center;
  transition: background 0.2s ease;

  ${props => props.open && css`
    background: ${props => props.theme.primaryDark};
    transition: background 0.3s ease;
  `}

  @media ${media.laptop} {
    display: none;
  }
`;

const Bar = styled.div`
  width: 2rem;
  border-radius: 1px;
  height: 2px;
  background: ${props => props.theme.appWhite};
  transition: 0.2s ease;
`;

const BarOne = styled(Bar)`
  margin-bottom: 0.4rem;

  ${props => props.open && css`
    transition: 0.3s ease;
    transform: translateY(0.5rem) rotate(45deg); 
  `}
`;
const BarTwo = styled(Bar)`
  margin-bottom: 0.4rem;

  ${props => props.open && css`
    transition: 0.3s ease;
    width: 0;
  `}
`;

const BarThree = styled(Bar)`
  ${props => props.open && css`
    transition: 0.3s ease;
    transform: translateY(-0.5rem) rotate(-45deg); 
  `}
`;

export const DrawerButton = (props) => (
  <Container onClick={props.onClick} open={props.showMenu}>
    {/* <StyledMenuIcon /> */}
    <BarOne open={props.showMenu}/>
    <BarTwo open={props.showMenu}/>
    <BarThree open={props.showMenu}/>
  </Container>
)

export default DrawerButton;