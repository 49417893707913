import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { media } from 'styles/utils/media';
import { mdCaption } from 'styles/TextStyles';

const NavbarTab = styled.li`
  flex: 0 0 4rem;
  display: flex;
  flex-direction: column;
  position: relative;

  border-width: 0 0 2px 0;
  border-style: solid;
  border-color: ${props => props.theme.primary};
  
  ${props => props.isDropdown && css`
    & > a:first-child {
      display: none;
    }

    @media ${media.laptop} {
      & > a:first-child {
        display: flex;
      }

      &:hover {
        background: ${props => props.theme.primaryDark};
        border-left-width: 5px;
        border-left-color: ${props => props.theme.secondary};

        div {
          display: flex;
        }
      }
    }
  `}

  ${props => props.active && css`
    background: ${props => props.theme.primaryDark};
    border-left-width: 5px;
    border-left-color: ${props => props.theme.secondary};
  `}

  ${props => props.alignRight && css`
    margin-top: auto;
    border-width: 2px 0 0 0;

    &:last-child {
      margin-top: 0;
    }
  `}

  @media ${media.laptop} {
    flex: 0 0 7.5rem;
    border: none;

    ${props => props.alignRight && css`
      margin-top: 0;
      margin-left: auto;

      &:last-child {
        margin-left: 0;
      }
    `}
  };
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.primaryLightest};
  flex-basis: 100%;
  -webkit-tap-highlight-color: transparent;
  padding-left: 1rem; 

  display: flex;
  align-items: center;
  position: relative;

  &:active {
    background: ${props => props.theme.primaryLight};
  }

  &:hover {
    background: ${props => props.theme.primaryDark}
  }

  ${props => props.active && css`
    padding-left: 11px;
  `}

  @media ${media.laptop} {
    justify-content: center;
    padding-left: 0rem;
  };
`;

const LinkText = styled(mdCaption)`
    line-height: 0.75rem;
    font-size: 1rem;

    color: ${props => props.theme.primaryLightest};

    transition: color 0.1s ease;
`;

// export const TabHighlight = styled.hr`
//   height: 0.1rem;
//   margin: 0;
//   background: ${props => props.theme.secondary};
//   opacity: ${props => props.active ? 0.7 : 0};
//   border: none;
//   transition: opacity 0.1s ease;

//   display: none;

//   @media ${media.laptop} {
//     display: block;
//     order: 1;
//     margin-bottom: 0;
//     margin-top: -0.3rem;
//     margin-left: 0.3rem;
//     margin-right: 0.3rem;
//   }
// `;

const DropdownContent = styled.div`
  flex: 0 0 4rem;
  display: flex;

  div {
    display: none;

    &:last-child {
      display: flex;
    }
  }

  @media ${media.laptop} {
    background: ${props => props.theme.primary};
    position: absolute;
    padding: 0;
    top: 3.5rem;
    display: flex;
    flex-direction: column;
    width: 10rem;
    z-index: 2;

    display: none;
  }
`;

const DropdownList = styled.div`
  flex: 0 0 100%;
  display: flex;

  @media ${media.laptop} {
    display: flex;
    flex: 0 0 3.5rem;
    position: relative;
  }
`;

const Dropdown = (props) => (
  <React.Fragment>
    <StyledLink to="#">
      <LinkText>Mer</LinkText>
    </StyledLink>
    <DropdownContent id="dd-container">
      { props.links.map(id => (
        <DropdownList key={id.linkRef}>
          <StyledLink to={id.linkRef} onClick={id.onClick(id.linkRef)}>
            <LinkText>{id.linkText}</LinkText>
          </StyledLink>
        </DropdownList>
      ))}
    </DropdownContent>
  </React.Fragment>
)

const Tab = (props) => {
  const active = props.active === props.linkRef;

  return (
    <NavbarTab active={active} alignRight={props.alignRight} isDropdown={props.isDropdown}>
      {
        props.isDropdown ?
        <Dropdown links={props.dropdown} /> :
        <StyledLink to={props.linkRef} onClick={props.onClick(props.linkRef)}>
          <LinkText>{props.linkText}</LinkText>
        </StyledLink>
      }
    </NavbarTab>
  )
}

export default Tab;