import styled from 'styled-components';
import { media } from 'styles/utils/media';

export const Container = styled.div.attrs(props => ({
  alignItems: props.isLoading ? "center" : "default",
  justifyContent: props.isLoading ? "center" : "default"
}))`
  display: flex;
  width: 96%;

  flex-basis: auto;
  flex-grow: 1;
  flex-direction: column;
  align-self: center;

  align-items: ${props => props.alignItems};
  justify-content: ${props => props.justifyContent};

  @media ${media.laptop} {
    width: 80%;
  }
`;