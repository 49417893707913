import React from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';
import Button from 'components/button';
import { mdSubtitle } from 'styles/TextStyles';
import Spinner from './spinner/spinner';

const Container = styled.div`
  flex: 0 0 3.5rem;
  order: 2;
  background: ${props => props.theme.primary};
  padding-right: 10%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const ButtonRow = styled.div`
  button {
    margin: 0;

    & + button {
      margin-left: 0.5rem;
    }
  }
`;

const StatusText = styled.div`
  margin-right: 1rem;
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  align-items: center;

  ${props => props.isError && css`
    color: ${props => props.theme.error};
    width: auto;
  `}

  ${props => props.isSuccess && css`
    color: ${props => props.theme.success};
    width: auto;
  `}
`;

const StyledText = styled(mdSubtitle)`
  margin: 0;
`;

const Footer = (props) => {
  return ReactDOM.createPortal(
    <Container>
      <StatusText isSuccess={props.isSuccess} isError={props.isError}>
        { props.isLoading ? 
          <Spinner color={"#FEFEFE"} width={"1.5rem"} height={"1.5rem"} /> :
          <StyledText>{props.statusText}</StyledText>
        }
      </StatusText>
      <ButtonRow>
        { props.children }
        <Button onClick={props.onClick} text={"Spara"} isCancel={true} />
      </ButtonRow>
    </Container>,
    document.getElementById("app-root")
  );
}

export default Footer;