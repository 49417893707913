import {
  REC_ARTICLES,
  REC_ARTICLES_FAILED,
  REQ_ARTICLES,
  ADD_ARTICLE_OK,
  ADD_ARTICLE_ERROR,
  REMOVE_ARTICLE_OK,
} from "./actions";

const initialState = {
  articles: [],
  _lastUpdated: 0,
};

function articles(state = initialState, action) {
  switch (action.type) {
    case REQ_ARTICLES:
      return Object.assign({}, state, {
        _isLoading: true,
        _isError: false,
      });
    case REC_ARTICLES:
      return Object.assign({}, state, {
        articles: action.articles,
        _isLoading: false,
        _isError: false,
        _lastUpdated: Date.now(),
      });
    case REC_ARTICLES_FAILED:
      return Object.assign({}, state, {
        _isLoading: false,
        _isError: true,
      });
    case ADD_ARTICLE_OK:
      return {
        ...state,
        new_article_ok: true,
      };
    case ADD_ARTICLE_ERROR:
      return {
        ...state,
        new_article_error: true,
      };
    case REMOVE_ARTICLE_OK:
      const newArticles = state.articles.filter(
        (article) => article._id !== action.id
      );

      return {
        ...state,
        articles: newArticles,
      };
    default:
      return state;
  }
}

export default articles;
