import apiService from 'services/api';

export const GET_COURSES = 'get_courses_schedule';
export const GET_COURSES_OK = 'get_courses_schedule_ok';
export const GET_COURSES_FAIL = 'get_courses_schedule_fail';
export const GET_SCHEDULE = 'get_schedule';
export const GET_SCHEDULE_OK = 'get_schedule_ok';
export const GET_SCHDULE_FAIL = 'get_schedule_fail';
export const REMOVE_WEEK = 'remove_week';
export const REMOVE_WEEK_OK = 'remove_week_ok';
export const REMOVE_WEEK_FAIL = 'remove_week_fail';
export const ADD_SCHEDULE = 'add_schedule';
export const ADD_SCHEDULE_OK = 'add_schedule_ok';
export const ADD_SCHEDULE_FAIL = 'add_schedule_fail';
export const NEW_WEEK = 'add_week';
export const UPDATE_WEEK = 'update_week';

export const newWeek = (id) => ({
  type: NEW_WEEK,
  id: id
});

export const updateWeek = (id, field, data) => dispatch => {
  if (field === 'nr' && data !== '') {
    data = parseInt(data, 10);
  }

  dispatch({
    type: UPDATE_WEEK,
    id: id,
    field: field,
    data: data
  });
}

export const deleteWeek = id => (dispatch, getState) => {
  const week = getState().schedule.weeks[id];

  dispatch({
    type: REMOVE_WEEK
  });

  if (!week._id) {
    dispatch({
      type: REMOVE_WEEK_OK,
      id: id
    })
  } else {
    apiService.deleteWeek(id).then(res => {
      dispatch({
        type: REMOVE_WEEK_OK,
        id: id
      })
    }).catch(err => {
      dispatch({
        type: REMOVE_WEEK_FAIL
      });
    });
  }
}

export const getCourses = (force) => (dispatch, getState) => {
  const lastFetched = getState().schedule._lastUpdated;
  const isStale = Math.abs(Math.round((Date.now() - lastFetched) / 36e5)) >= 2;

  if (isStale || force) {
    dispatch({
      type: GET_COURSES
    });

    apiService.getCoursesForSchedule().then(res => {
      dispatch({
        type: GET_COURSES_OK,
        courses: res.data.courses,
        tees: res.data.tees
      });
    }).catch(err => {
      dispatch({
        type: GET_COURSES_FAIL
      });
    });
  }
}

export const getSchedule = (force) => (dispatch, getState) => {
  const lastFetched = getState().schedule._lastUpdated;
  const isStale = Math.abs(Math.round((Date.now() - lastFetched) / 36e5)) >= 2;

  if (isStale || force) {
    dispatch({
      type: GET_SCHEDULE
    });

    apiService.getSchedule(2019).then(res => {
      dispatch({
        type: GET_SCHEDULE_OK,
        data: res.data
      });
    }).catch(err => {
      dispatch({
        type: GET_SCHDULE_FAIL
      });
    });
  }
}

export const addSchedule = () => (dispatch, getState) => {
  let weeks = getState().schedule.weeks,
      id = getState().schedule._id;

  dispatch({
    type: ADD_SCHEDULE
  });

  apiService.addSchedule({ year: 2019, weeks: weeks, _id: id }).then(res => {
    dispatch({
      type: ADD_SCHEDULE_OK,
      data: res.data,
    });
  }).catch(err => {
    dispatch({
      type: ADD_SCHEDULE_FAIL
    })
  })
}