import React from 'react';
import styled from 'styled-components';

import PlayerCard from './playercard';
import { NoResult } from 'styles/ScorecardStyles';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  justify-content: center;
`;

const getCards = (ids, players, onChange, holes, isEdit, winners, showPoints, onDel) => {
  if (Object.keys(players).length === 0) {
      return (
        NoResult()
      )
  }

  return (
    <React.Fragment>
      {
        ids.map(i => (
          <PlayerCard 
            key={i} 
            player={players[i]} 
            onChange={onChange} 
            holes={holes} 
            isEdit={isEdit} 
            winner={winners[i]}
            showPoints={showPoints}
            onDelete={onDel(i)}
          />
        ))
      }
    </React.Fragment>
  )
}

const ResultList = (props) => (
  <Container>
    { getCards(props.playerIds, props.players, props.onChange, props.holes, props.isEdit, props.winners, props.showPoints, props.onDelete) }
  </Container>
);

export default ResultList;