import React, { Component } from 'react';
import styled from 'styled-components';
import { 
  NameContainer, 
  StyledHoleNrBar, 
  ScoreBarContainer, 
  HoleNr, 
  ScoreNr,
  PlayerName,
  PlayerScore,
  PlayerPoints,
  ScoreContainer,
  ScoreInput
} from 'styles/ScorecardStyles';
import { getScoreColor } from 'utils/hcpUtils';
import { Cross } from 'styled-icons/icomoon';

const Container = styled.div`
  width: 100%;
  background: ${props => props.theme.appWhite};
  margin-bottom: 0.4rem;
  position: relative;
`;

const HoleNrBar = (from, to, id) => {
  var holes = [];

  for (var i = from; i <= to; ++i) {
    holes.push(i);
  }

  return (
    <StyledHoleNrBar>
      {
        holes.map(nr => (
          <HoleNr key={`${id}_hole_${nr}`}>{nr}</HoleNr>
        ))
      }
    </StyledHoleNrBar>
  )
}
  
const ScoreBar = (min, max, onChange, player, courseHoles, isEdit) => {
  var holes = [];

  for (var i = min; i <= max; ++i) {
    holes.push(i);
  } 

  return (
    <ScoreBarContainer autoComplete="off">
      {
        holes.map(nr => {
          if (isEdit) {
            return (
              <ScoreInput 
              key={`${player.player}_${nr}`}
              name={`${player.player}_${nr}`}
              maxLength="2"
              value={player.scores[nr].strokes || ''} 
              onChange={onChange}
              scoreColor={getScoreColor(player.scores[nr].strokes, courseHoles[nr].par)}
            />
            )
          } else {
            return (
              <ScoreNr key={`${player.player}_${nr}`} scoreColor={getScoreColor(player.scores[nr].strokes, courseHoles[nr].par)}>
                {player.scores[nr].strokes}
              </ScoreNr>
            )
          }      
        })
      }
    </ScoreBarContainer>
  )
}

const TotInfoBar = (id) => {
  var holes = ["Ut", "In", "Brutto", "Netto"];

  return (
    <StyledHoleNrBar>
      {
        holes.map(nr => (
          <HoleNr key={`${id}_info_${nr}`}>{nr}</HoleNr>
        ))
      }
    </StyledHoleNrBar>
  )
}

const TotScoreBar = (values, id) => {
  return (
    <ScoreBarContainer>
      {
        values.map((nr, index) => (
          <ScoreNr key={`${id}_tot_score_${index}`}>{nr}</ScoreNr>
        ))
      }
    </ScoreBarContainer>
  )
}

const StyledButton = styled.button`
  position: absolute;
  top: 0.9rem;
  right: -1.5rem;
  padding: 0;
  border: none;
  outline: none;
  line-height: 0;
  background: transparent;

  &:active {
    color: ${props => props.theme.secondary};
  }
`;

const StyledExit = styled(Cross)`
  height: 0.7rem;
  width: 0.8rem;
  margin: 0;
`;

export const DeleteButton = (props) => (
  <StyledButton onClick={props.onClick}>
    <StyledExit />
  </StyledButton>
)

class PlayerCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };
    
    this.onTileClick = this.onTileClick.bind(this);
  }

  onTileClick() {
    this.setState((state) => {
      return { open: !state.open };
    });
  }

  render() {
    const { firstname, lastname, netTotal, grossIn, grossOut, grossTotal } = this.props.player;

    return (
      <Container>
        <NameContainer onClick={this.onTileClick} open={this.state.open}>
          <PlayerName>{`${firstname} ${lastname}`}</PlayerName>
          { this.props.winner && this.props.winner.amount && this.props.showPoints ? <PlayerPoints>{`+${this.props.winner.amount}`}</PlayerPoints> : "" }
          <PlayerScore>{netTotal}</PlayerScore>
        </NameContainer>
        {this.props.isEdit ? <DeleteButton onClick={this.props.onDelete} /> : "" }
        <ScoreContainer open={this.state.open}>
          {HoleNrBar(1, 9, this.props.player.player)}
          {ScoreBar(1, 9, this.props.onChange, this.props.player, this.props.holes, this.props.isEdit)}
          {HoleNrBar(10, 18, this.props.player.player)}
          {ScoreBar(10, 18, this.props.onChange, this.props.player, this.props.holes, this.props.isEdit)}
          {TotInfoBar(this.props.player.player)}
          {TotScoreBar([grossOut, grossIn, grossTotal, netTotal], this.props.player.player)}
        </ScoreContainer>
      </Container>
    )
  }
}

export default PlayerCard;