import React, { Component } from "react";
import { connect } from "react-redux";

import {
  getAllArticles,
  articlesIsLoading,
  articlesIsError,
} from "redux/articles/selectors";
import { isAdmin } from "redux/auth/selectors";
import { getArticles, deleteArticle } from "redux/articles/actions";

import Spinner from "components/spinner/spinner";
import Article from "components/article/article";
import { Container } from "./styled";

const mapStateToProps = (state) => {
  return {
    articles: getAllArticles(state),
    isLoading: articlesIsLoading(state),
    isError: articlesIsError(state),
    isAdmin: isAdmin(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getArticles: () => {
      dispatch(getArticles());
    },
    removeArticle: (id) => {
      dispatch(deleteArticle(id));
    },
  };
};

class Home extends Component {
  componentDidMount() {
    this.props.getArticles();
  }

  getSpinner() {
    return <Spinner />;
  }

  createArticles() {
    const articleList = this.props.articles.map((article) => (
      <Article
        article={article}
        key={article._id}
        isAdmin={this.props.isAdmin}
        onDelete={() => this.props.removeArticle(article._id)}
      />
    ));

    return articleList;
  }

  render() {
    const { isLoading } = this.props;

    return (
      <Container isLoading={isLoading}>
        {isLoading ? this.getSpinner() : this.createArticles()}
      </Container>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
