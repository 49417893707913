import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom'

import { styledH4, mdCaption } from 'styles/TextStyles';
import InputWithLabel from 'components/inputWithLabel';
import Button from 'components/button';
import { Form, FormTitle, BtnRow } from 'styles/FormStyles';
import { ErrorBox, SuccessBox } from 'components/errorBox';

const Title = styled(styledH4)`
  margin: 0;
`;

const StyledLink = styled(Link)`
  color: ${props => props.theme.primaryLightest};
  -webkit-tap-highlight-color: transparent;

  display: flex;
  justify-content: center;
  position: relative;
  padding: 1rem 0 0.5rem;
`;

const LinkText = styled(mdCaption)`
  font-size: 0.9rem;
  color: ${props => props.theme.primaryLightest};
`;

const LoginForm = (props) => (
  <Form>
    <FormTitle>
      <Title>Inloggning</Title>
    </FormTitle>

    {props.isReset && <InputWithLabel label="Email" type="email" name="username" onChange={props.onEmail} value={props.email} /> }

    {props.isError ? <ErrorBox desc={props.error} /> : ""}
    {props.isSuccess ? <SuccessBox desc={props.statusText} /> : ""}

    <BtnRow>
      { !props.isReset ? <Button text="Logga in" onClick={props.onLogin} /> : <Button text="Tillbaka" onClick={props.toggleReset} /> }
      { props.isReset ? <Button text="Återställ lösenord" onClick={props.onReset} /> : "" }
    </BtnRow>
    { !props.isReset ?
      <StyledLink to='#' onClick={props.toggleReset}>
        <LinkText>Återställ lösenord</LinkText>
      </StyledLink> : "" }

  </Form>
);

export default LoginForm;