import React from 'react';
import styled, { css } from 'styled-components';
import { media } from 'styles/utils/media';
import { Cross } from 'styled-icons/icomoon';

import { styledH6, mdCaption, mdBody, mdSubtitle2 } from 'styles/TextStyles';
import { TileContainer } from 'styles/TileStyles';
import { getWeekDatesStr, getCurrentWeek } from 'utils/DateUtils';

const StyledTileContainer = styled(TileContainer)`
  display: flex;
  position: relative;

  @media ${media.laptop} {
    flex-direction: column;
    flex: 0 1 22%;

    margin-bottom: 1rem;
    margin-right: 2%;

    &:nth-child(4n) {
      margin-right: 3%;
    }

    &:nth-child(4n+1) {
      margin-left: 3%;
    }
  }
`;

const WeekNrContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 5rem;
  background: ${props => props.theme.primaryLightest};
  padding: 0.5rem;
  justify-content: center;

  ${props => props.current && css`
    background: ${props => props.theme.secondary};
  `}
`;

const WeekNr = styled(styledH6)`
  color: ${props => props.theme.primaryHeaderText};
  text-align: center;
  margin: 0;

  @media ${media.laptop} {
    font-size: 3rem;
  }
`;

const WeekDates = styled(mdCaption)`
  color: ${props => props.theme.secondaryText};
  text-align: center;
`;

const WeekCourse = styled(mdBody)`
  margin: 0;
  color: ${props => props.theme.primaryText};
`;

const WeekTee = styled(mdSubtitle2)`
  margin: 0;
  color: ${props => props.theme.secondaryText};
`;

const WeekInfoContainer = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.5rem;
`;

const StyledButton = styled.button`
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;
  padding: 0;
  border: none;
  outline: none;
  line-height: 0;
  background: transparent;

  &:active {
    color: ${props => props.theme.secondary};
  }
`;

const StyledInput = styled.input`
  background: transparent;
  border: none;
  outline: none;
  border-bottom: 1px solid ${props => props.theme.primaryHeaderText};
  color: ${props => props.theme.primaryHeaderText};
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.009375rem;
  width: 4rem;
  align-self: center;
  margin-bottom: 0.5rem;
  text-align: center;

  @media ${media.laptop} {
    font-size: 3rem;
  }
`;

const StyledExit = styled(Cross)`
  height: 0.7rem;
  width: 0.8rem;
  margin: 0;
`;

const DeleteButton = (props) => (
  <StyledButton onClick={props.onClick}>
    <StyledExit />
  </StyledButton>
)

const CourseSelector = (props) => (
  <React.Fragment>
    <select value={props.course} onChange={props.eventInput(props.id, 'course')}>
      {
        props.coursesById.map(id => (
          <option key={id} value={id}>{props.courses[id].name}</option>
        ))
      }
    </select> 
  </React.Fragment>
)

const TeeSelector = (props) => {
  let tees = props.courses[props.course].tees;

  return (
    <React.Fragment>
      <select value={props.tee} onChange={props.eventInput(props.id, 'tee')}>
        {
          Object.keys(tees).map(t => (
            <option key={tees[t]._id} value={tees[t]._id}>{tees[t].name}</option>
          ))
        }
      </select> 
    </React.Fragment>
  );
}

const WeekTile = (props) => (
  <StyledTileContainer key={props.id}>
    <WeekNrContainer current={getCurrentWeek() === props.nr}>
      {props.isEdit ? <StyledInput maxLength="2" value={props.nr} onChange={props.eventInput(props.id, 'nr')}/> : <WeekNr>{`v.${props.nr}`}</WeekNr>}
      <WeekDates>{getWeekDatesStr(props.nr)}</WeekDates>
    </WeekNrContainer>
    <WeekInfoContainer>
      { props.isEdit ? <CourseSelector {...props} /> : <WeekCourse>{props.courses[props.course].name}</WeekCourse> }
      { props.isEdit ? <TeeSelector {...props} /> : <WeekTee>Tee: { props.tee ? props.tees[props.tee].name : "" }</WeekTee> }
    </WeekInfoContainer>
    { props.isEdit ? <DeleteButton onClick={props.onRemove(props.id)} /> : ""}
  </StyledTileContainer>
)

export default WeekTile;