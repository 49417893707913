import styled from 'styled-components';

const fonts = {
  heading: 'Work Sans, sans-serif',
  subtitle: 'Work Sans, sans-serif',
  body: 'Work Sans, sans-serif',
  button: 'Work Sans, sans-serif',
  caption: 'Work Sans, sans-serif'
}

export const styledH1 = styled.h1`
  font-family: ${fonts.heading};
  font-size: 6rem;
  font-weight: 300;
  letter-spacing: 0.09375rem;
`;

export const styledH2 = styled.h2`
  font-family: ${fonts.heading};
  font-size: 3.75rem;
  font-weight: 300;
  letter-spacing: -0.03125rem;
`;

export const styledH3 = styled.h3`
  font-family: ${fonts.heading};
  font-weight: 400;
  font-size: 3rem;
`;

export const styledH4 = styled.h4`
  font-family: ${fonts.heading};
  font-size: 2.125rem;
  font-weight: 400;
  letter-spacing: 0.015625rem;
`;

export const styledH5 = styled.h5`
  font-family: ${fonts.heading};
  font-weight: 400;
  font-size: 1.5rem;
`;

export const styledH6 = styled.h6`
  font-family: ${fonts.heading};
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.009375rem;
`;

export const mdSubtitle = styled.p`
  font-family: ${fonts.subtitle};
  font-size: 1rem;
  letter-spacing: 0.009375rem;
`;

export const mdSubtitle2 = styled.p`
  font-family: ${fonts.subtitle};
  font-size: 0.875rem;
  letter-spacing: 0.009375rem;
`;

export const mdBody = styled.p`
  font-family: ${fonts.body};
  font-size: 1rem;
  letter-spacing: 0.03125rem;
`;

export const mdBody2 = styled.p`
  font-family: ${fonts.body};
  font-size: 0.875rem;
  letter-spacing: 0.015625rem;
`;

export const mdButton = styled.button`
  font-family: ${fonts.button};
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.078125rem;
  font-weight: 500;
`;

export const mdCaption = styled.span`
  font-family: ${fonts.caption};
  font-size: 0.75rem;
  letter-spacing: 0.025rem;
`;

export const mdOverline = styled.span`
  font-family: ${fonts.caption};
  font-size: 0.75rem;
  letter-spacing: 0.09375rem;
`;