export const isFetching = (state) => state.schedule._isFetchingCourses || state.schedule_isFetchingSchedule;
export const isError = (state) => state.schedule._isError;

export const isLoading = state => state.schedule._isLoading;
export const isSuccess = state => state.schedule._isSuccess;
export const getStatusText = state => state.schedule._statusText;

export const nextId = state => state.schedule._nextWeekId;

export const getAllCourses = state => state.schedule.courses;
export const getCoursesById = state => state.schedule.coursesById;
export const getTees = state => state.schedule.tees;

export const getWeeks = (state) => state.schedule.weeks;
export const getWeeksById = (state) => {
  return state.schedule.weekById.sort((a, b) => {
    return state.schedule.weeks[a].nr - state.schedule.weeks[b].nr;
  });
};

