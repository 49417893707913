import styled from 'styled-components';
import { media } from 'styles/utils/media';

export const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  flex: 0 0 3.5rem;
  position: sticky;
  z-index: 1;
  top: 0;

  background: ${props => props.theme.primary};

  @media ${media.tablet} {
    justify-content: center;
  };

  @media ${media.laptop} {
    height: 3rem;
    flex: 0 0 3.5rem;
    justify-content: flex-start;

    padding-right: 10%;
  };

`;