import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { styledH6 } from 'styles/TextStyles';
import Button from './button';

const appRoot = document.getElementById("root");

const ModalBackground = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
`;

const ModalDialog = styled.div`
  height: 8rem;
  width: 12rem;
  background: ${props => props.theme.appWhite};
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.3rem;
`;

const ModalText = styled(styledH6)`
  margin: 0;
`;

const ButtonRow = styled.div`
  display: flex;
`;

const TestModal = (props) => {
  return ReactDOM.createPortal(
    <ModalBackground>
      <ModalDialog>
        <ModalText>Är du säker?</ModalText>
        <ButtonRow>
          <Button onClick={props.onCancel} text={"Avbryt"} isCancel={true} />
          <Button onClick={props.onConfirm} text={"OK"} />
        </ButtonRow>
      </ModalDialog>
    </ModalBackground>,
    appRoot
  )
}

class Modal extends Component {
  render() {
    return (
      <React.Fragment>
        { this.props.isOpen ? <TestModal onConfirm={this.props.onConfirm} onCancel={this.props.onCancel} /> : "" } 
      </React.Fragment>
    )
  }
}

export default Modal;