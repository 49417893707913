import authService from 'services/auth';

export const REQ_LOGIN = 'REQ_LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT = 'LOGOUT';
export const RENEW_TOKEN_SUCCESS = 'RENEW_TOKEN_SUCCESS';
export const RENEW_TOKEN_FAILED = 'RENEW_TOKEN_FAILED';
export const RESET_PASS = 'reset_pass';
export const RESET_PASS_OK = 'reset_pass_ok';
export const RESET_PASS_FAIL = 'reset_pass_fail';

export const login = (user) => (dispatch, getState) => {
  dispatch({ type: REQ_LOGIN });

  authService.login(user).catch((err) => {
    dispatch({ type: LOGIN_FAILED, err })
  });
}

export const parseHash = () => (dispatch, getState) => {
  authService.handleAuthentication().then(() => {
    return dispatch({ type: LOGIN_SUCCESS });
  }).catch(err => {
    console.log(err);
    return dispatch({ type: LOGIN_FAILED, err });
  });
}

export const logout = () => {
  authService.logout();

  return {
    type: LOGOUT
  }
}

export const renewTokenSuccess = () => {
  return {
    type: RENEW_TOKEN_SUCCESS
  }
}

export const renewTokenFailed = () => {
  return {
    type: RENEW_TOKEN_FAILED
  }
}

export const changePassword = (email) => dispatch => {
  dispatch({
    type: RESET_PASS
  });

  authService.changePassword(email).then(res => {
    dispatch({
      type: RESET_PASS_OK
    })
  }).catch(err => {
    dispatch({
      type: RESET_PASS_FAIL
    })
  });
}