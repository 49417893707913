export const isFetching = (state) => state.results._isFetching || 
                                     state.results._isFetchingRanges ||
                                     state.courses.isFetchingCourses ||
                                     state.results._isFetchingPlayers;

export const isError = (state) => state.results._isError;
export const isSuccess = state => state.results._isSuccess;
export const getResults = state => state.results.results[state.results.currentWeek];
export const endWeek = state => state.results.endWeek;
export const startWeek = state => state.results.startWeek;
export const lastWeek = state => state.results.lastWeek;
export const currentWeek = state => state.results.currentWeek;
export const statusText = state => state.results._statusText;
export const getPlayerIds = state => {
  const { sort, playersById } = state.results;

  if (!state.results.results[state.results.currentWeek]) {
    return playersById;
  } 

  const { players } = state.results.results[state.results.currentWeek];

  if (sort === 'score') {
    return playersById.sort((a, b) => {
      return players[a].netTotal - players[b].netTotal;
    });
  }

  if (sort === 'alpha') {
    return playersById.sort((a, b) => {
      if (players[a].lastname < players[b].lastname) {
        return -1;
      } else if (players[a].lastname > players[b].lastname) {
        return 1;
      }
  
      return 0;
    });
  }

  if (sort === 'played') {
    return getPlayed(state);
  }

  return playersById;
};
export const getPlayed = state => {
  const { players } = state.results.results[state.results.currentWeek];

  var tmp = state.results.playersById.filter(id => {
    return players[id].grossTotal >= 50;
  });

  return tmp.sort((a, b) => {
    return players[a].netTotal - players[b].netTotal
  });
};
export const getLeaderIds = state => {
  const { leadersById, leaders } = state.results;

  return leadersById.sort((a, b) => {
    return leaders[b].points - leaders[a].points;
  });
}
export const getLeaders = state => state.results.leaders;
export const isFetchingLeaders = state => state.results._isFetchingLeaders;