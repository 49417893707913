import React from "react";
import styled, { css } from "styled-components";
import { media } from "styles/utils/media";

import Tab from "components/navbar/Tab";

const ListContainer = styled.div`
  width: 100%;
  display: flex;
  position: absolute;
  top: 3.5rem;
  height: calc(100vh - 56px);
  transform: translateX(-100%);
  transition: transform 0.2s ease;

  ${(props) =>
    props.show &&
    css`
      transform: translateX(0%);
      transition: transform 0.3s ease;
    `}

  @media ${media.laptop} {
    position: static;
    flex-basis: 100%;
    height: 100%;
    order: 1;
    transform: translateX(0%);
  }
`;

const StyledTabList = styled.ul`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  background: ${(props) => props.theme.primaryDark};

  margin: 0;
  padding: 0;

  @media ${media.laptop} {
    background: transparent;
    flex-direction: row;
    flex-wrap: nowrap;

    justify-content: flex-start;
  }
`;

const TabList = (props) => {
  const dropdown = [
    { linkRef: "/logout", linkText: "Logga ut", onClick: props.onLogout },
  ];

  if (props.isAdmin) {
    dropdown.unshift({
      linkRef: "/add_users",
      linkText: "Spelare",
      onClick: props.onTabClick,
    });
    dropdown.unshift({
      linkRef: "/new_schedule",
      linkText: "Schema",
      onClick: props.onTabClick,
    });
    dropdown.unshift({
      linkRef: "/new_result",
      linkText: "Resultat",
      onClick: props.onTabClick,
    });
    dropdown.unshift({
      linkRef: "/new_post",
      linkText: "Inlägg",
      onClick: props.onTabClick,
    });
  }

  return (
    <ListContainer show={props.showMenu}>
      <StyledTabList>
        {props.isLoggedIn ? (
          <React.Fragment>
            <Tab
              linkRef="/"
              linkText="Hem"
              onClick={props.onTabClick}
              active={props.activeTab}
            />
            <Tab
              linkRef="/result"
              linkText="Resultat"
              onClick={props.onTabClick}
              active={props.activeTab}
            />
            <Tab
              linkRef="/schedule"
              linkText="Schema"
              onClick={props.onTabClick}
              active={props.activeTab}
            />
            <Tab
              linkRef="/leaderboard"
              linkText="Ledartavla"
              onClick={props.onTabClick}
              active={props.activeTab}
            />
            <Tab
              linkRef="/profile"
              linkText="Min profil"
              onClick={props.onTabClick}
              active={props.activeTab}
              alignRight={true}
            />
            <Tab
              dropdown={dropdown}
              active={false}
              alignRight={true}
              isDropdown={true}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Tab
              linkRef="/login"
              linkText="Logga in"
              onClick={props.onTabClick}
              active={props.activeTab}
              alignRight={true}
            />
          </React.Fragment>
        )}
      </StyledTabList>
    </ListContainer>
  );
};

export default TabList;
