import { SET_ACTIVE_TAB, TOGGLE_MENU } from './actions';

const initialState = {
  tab: '/',
  menuOpen: false
}

function navbar(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_TAB:
      return {
        ...state,
        tab: action.tab,
        menuOpen: false
      }
    case TOGGLE_MENU:
      return {
        ...state,
        menuOpen: !state.menuOpen
      }
    default:
      return state;
  }
}

export default navbar;