import React from "react";
import styled from "styled-components";
import { styledH5 } from "styles/TextStyles";
import { Cross } from "styled-icons/icomoon";

const Title = styled.div`
  width: 100%;
  display: flex;
`;

const Text = styled(styledH5)`
  margin-top: 0;
  margin-bottom: 0;
  color: ${(props) => props.theme.primaryHeaderText};
  flex: 1;
`;

const StyledButton = styled.button`
  padding: 0;
  border: none;
  outline: none;
  line-height: 0;
  background: transparent;

  &:active {
    color: ${(props) => props.theme.secondary};
  }
`;

const StyledExit = styled(Cross)`
  height: 0.7rem;
  width: 0.8rem;
  margin: 0;
`;

export const DeleteButton = (props) => (
  <StyledButton onClick={props.onClick}>
    <StyledExit />
  </StyledButton>
);

const ArticleTitle = (props) => (
  <Title>
    <Text>{props.text}</Text>
    {props.isAdmin ? <DeleteButton onClick={props.onDelete} /> : null}
  </Title>
);

export default ArticleTitle;
