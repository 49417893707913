import React, { Component } from 'react';
import { connect } from 'react-redux';

import { setActiveTab, toggleMenu } from 'redux/navbar/actions';
import { getActiveTab, showMenu } from 'redux/navbar/selectors';
import { isLoggedIn, isAdmin } from 'redux/auth/selectors';
import { logout } from 'redux/auth/actions';

import TabList from 'components/navbar/tabList';
import Logo from 'components/navbar/logo';
import DrawerButton from 'components/navbar/drawerButton';
import { Nav } from './styled';

const mapStateToProps = (state) => {
  return {
    activeTab: getActiveTab(state),
    showMenu: showMenu(state),
    isLoggedIn: isLoggedIn(state),
    isAdmin: isAdmin(state)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onTabClick: (tab) => {
      dispatch(setActiveTab(tab));
    },
    onMenuClick: () => {
      dispatch(toggleMenu());
    },
    onLogoutClick: () => {
      dispatch(logout());
    }
  }
}

class Navbar extends Component {
  tabClick = (id) => (event) => {
    this.props.onTabClick(id);
  }

  logoutClick = (id) => (event) => {
    this.props.onLogoutClick();
  }

  render() {
    const { activeTab, onMenuClick, showMenu, isLoggedIn, isAdmin } = this.props;

    return(
      <Nav>
        <Logo />
        <TabList onTabClick={this.tabClick} activeTab={activeTab} showMenu={showMenu} onLogout={this.logoutClick} isLoggedIn={isLoggedIn} isAdmin={isAdmin} />
        <DrawerButton onClick={onMenuClick} showMenu={showMenu} />
      </Nav>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navbar);