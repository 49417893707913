import authService from 'services/auth';
import {
  REQ_LOGIN,
  LOGIN_FAILED,
  LOGIN_SUCCESS, 
  RENEW_TOKEN_FAILED, 
  RENEW_TOKEN_SUCCESS, 
  LOGOUT,
  RESET_PASS_OK,
  RESET_PASS_FAIL
} from './actions';

const error_codes = {
  access_denied: "Fel email eller lösenord",
  invalid_request: "Email och/eller lösenord saknas"
}

const initialState = {
  isError: false,
  error: null,
  isFetching: false,
  isParsed: false,
  user: null,
  tokenRenewed: false,
  isLoggedIn: false,
  isAdmin: false,
  statusText: '',
  isSuccess: false
}

function auth(state = initialState, action) {
  switch(action.type) {
    case REQ_LOGIN: 
      return {
        ...state,
        isFetching: true
      }
    case LOGIN_FAILED: 
      return {
        ...state,
        isFetching: false,
        isError: true,
        error: error_codes[action.err.code],
        tokenRenewed: true
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        tokenRenewed: true,
        isParsed: true,
        isLoggedIn: authService.isAuthenticated(),
        isAdmin: authService.isAdmin()
      }
    case LOGOUT:
      return {
        ...state,
        tokenRenewed: false,
        isLoggedIn: authService.isAuthenticated(),
        isAdmin: false
      }
    case RENEW_TOKEN_SUCCESS:
      return {
        ...state,
        tokenRenewed: true,
        isLoggedIn: authService.isAuthenticated(),
        isAdmin: authService.isAdmin()
      }
    case RENEW_TOKEN_FAILED:
      return {
        ...state,
        tokenRenewed: true,
        isLoggedIn: false,
        isAdmin: false
      }
    case RESET_PASS_OK:
      return {
        ...state,
        isSuccess: true,
        isError: false,
        statusText: "Ett email har skickats med instruktioner"
      }
    case RESET_PASS_FAIL:
      return {
        ...state,
        isError: true,
        isSuccess: false,
        error: "Något gick fel!"
      }
    default: 
      return state
  }
}

export default auth;