import { combineReducers } from 'redux';
import navbar from './navbar/reducer';
import articles from './articles/reducer';
import courses from './courses/reducer';
import schedule from './schedule/reducer';
import results from './results/reducer';
import auth from './auth/reducer';
import users from './users/reducer'

export default combineReducers({
  navbar,
  articles,
  courses,
  schedule,
  auth,
  users,
  results
});
