import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { media } from 'styles/utils/media';
import Modal from 'components/confirmModal';
import Footer from 'components/footer';

import {
  getAllCourses,
  isFetching,
  isError,
  isSuccess, 
  getStatusText, 
  isLoading,
  nextId,
  getWeeks, 
  getWeeksById,
  getCoursesById,
  getTees
} from 'redux/schedule/selectors';
import { getCourses, newWeek, getSchedule, updateWeek, deleteWeek, addSchedule } from 'redux/schedule/actions';

import { Container } from './styled';
import { TileListContainer, TileContainer } from 'styles/TileStyles';
import WeekTile from 'components/schedule/weekTile';
import CircularButton from 'components/circularButton';
import Spinner from 'components/spinner/spinner';

const mapStateToProps = state => {
  return {
    courses: getAllCourses(state),
    tees: getTees(state),
    nextId: nextId(state),
    weeks: getWeeks(state),
    weeksById: getWeeksById(state),
    isFetching: isFetching(state),
    isError: isError(state),
    coursesById: getCoursesById(state),
    isSuccess: isSuccess(state),
    isLoading: isLoading(state),
    statusText: getStatusText(state)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getCourses: (force) => {
      dispatch(getCourses(force));
    },
    newWeek: (id) => {
      dispatch(newWeek(id));
    },
    getSchedule: (force) => {
      dispatch(getSchedule(force));
    },
    updateWeek: (id, field, data) => {
      dispatch(updateWeek(id, field, data));
    },
    deleteWeek: id => {
      dispatch(deleteWeek(id));
    },
    addSchedule: () => {
      dispatch(addSchedule());
    }
  }
}

const StyledButtonContainer = styled(TileContainer)`
  display: flex;
  align-items: center;
  justify-content: center;

  background: transparent;

  @media ${media.laptop} {
    height: 147px;
    flex: 0 1 22%;

    &:nth-child(4n) {
      margin-right: 3%;
    }

    &:nth-child(4n+1) {
      margin-left: 3%;
    }
  }
`;

class Schedule extends Component {
  constructor(props) {
    super(props);

    this.state = { isModalOpen: false, id: null };
  }

  componentDidMount() {
    this.props.getCourses(this.props.isEdit);
    this.props.getSchedule(this.props.isEdit);
  }

  addNewWeek() {
    this.props.newWeek(this.props.nextId);
  }

  handleInput = (id, type) => (event) => {
    this.props.updateWeek(id, type, event.target.value);
  }

  showConfirm = (id) => (event) => {
    this.setState((state) => {
      return { isModalOpen: !state.isModalOpen, id: id };
    });
  }

  delWeek = (id) => { 
    this.props.deleteWeek(id);
  }

  modalCallback = (isCancel) => (event) => {
    if (!isCancel) {
      this.delWeek(this.state.id);
    }

    this.setState((state) => {
      return { isModalOpen: !state.isModalOpen, id: null };
    });
  }

  render() {
    const { isError, isSuccess, isLoading, statusText } = this.props;

    return (
      <Container isLoading={this.props.isFetching} >
        { this.props.isFetching ? <Spinner /> :
        <TileListContainer>
          { this.props.weeksById.map(id => (
            WeekTile({
              id: id,  
              ...this.props.weeks[id],
              courses: this.props.courses,
              tees: this.props.tees,
              coursesById: this.props.coursesById,
              eventInput: this.handleInput,
              onRemove: this.showConfirm,
              isEdit: this.props.isEdit
            })
          )) }
          { this.props.isEdit ? 
            <StyledButtonContainer>
              <CircularButton onClick={this.addNewWeek.bind(this)}/>
            </StyledButtonContainer> : ""}
          
        </TileListContainer>
        }
        { this.props.isEdit ? 
          <React.Fragment>
            <Modal onConfirm={this.modalCallback(false)} onCancel={this.modalCallback(true)} isOpen={this.state.isModalOpen} />
            <Footer onClick={this.props.addSchedule} isError={isError} isSuccess={isSuccess} isLoading={isLoading} statusText={statusText} />
          </React.Fragment> : ""}
      </Container>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Schedule);