import React from 'react';
import ReactDOM from 'react-dom';
import styled, { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux'
import './index.scss';
import App from './App';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import store from './redux/store';
import authService from 'services/auth';
import { lightTheme } from 'themes/light';

import Navbar from 'containers/Navbar/Navbar';
import Login from "containers/Login/Login";
import AuthCallback from "containers/Login/authCb";

const Main = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={lightTheme}>
    <Router>
      <Main id="app-root">
      <Navbar />
      <Switch>
        <Route path="/login" component={Login} />
        <Route
          path="/auth"
          component={AuthCallback}
        />
        <Route path="/">
          <App auth={authService} />
        </Route>
      </Switch>
      </Main>
    </Router>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
