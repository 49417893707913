import React from 'react';
import styled, { css } from 'styled-components';
import { styledH6, mdCaption, mdBody } from 'styles/TextStyles';

export const NameContainer = styled.div`
  padding: 0.5rem;
  display: flex;
  background: ${props => props.theme.appWhite};
  transition: background 0.2s ease;
  position: relative;

  ${props => props.open && css`
    background: ${props => props.theme.secondary};
  `}
`;

export const PlayerName = styled(styledH6)`
  margin-top: 0;
  margin-bottom: 0;
  flex: 0 0 auto;
  color: ${props => props.theme.primaryHeaderText};
`;

export const PlayerScore = styled(styledH6)`
  margin-top: 0;
  margin-bottom: 0;
  flex: 1 0 auto;
  text-align: right;
  color: ${props => props.theme.secondaryText};
`;

export const PlayerPoints = styled(styledH6)`
  margin-top: 0;
  margin-bottom: 0;
  flex: 0 0 auto;
  padding-left: 0.5rem;
  color: ${props => props.theme.primaryLight};
`;

export const ScoreContainer = styled.div`
  display: flex;
  overflow: hidden;
  max-height: 0rem;
  width: 100%;
  flex-wrap: wrap;
  background: ${props => props.theme.primaryLightest};
  transition: max-height 0.2s ease-out;

  ${props => props.open && css`
    max-height: 12.5rem;
  `}
`;

export const StyledHoleNrBar = styled.div`
  display: flex;
  background: ${props => props.theme.primary};
  color: ${props => props.theme.primaryLightest};
  flex: 1 0 100%;

  &:nth-child(3),
  &:nth-child(5) {
    margin-top: 0.5rem;
  }
`;

export const HoleNr = styled(mdCaption)`
  flex: 1 1 0;
  text-align: center;
  align-content: center;

  &:nth-child(even) {
    border-left: 1px solid;
    border-right: 1px solid;
    border-color: ${props => props.theme.primaryLightest};
  }

  &:nth-child(odd) {
    padding-left: 1px;
    padding-right: 1px;
  }

  &:last-child {
    border-right: none;
    padding-right: 1px;
  }
`;

export const ScoreBarContainer = styled.form`
  display: flex;
  background: ${props => props.theme.appWhite};
  color: ${props => props.theme.primaryText};
  flex: 1 0 100%;
`;

export const ScoreNr = styled(mdBody)`
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  flex: 1 1 0;
  text-align: center;

  ${props => props.scoreColor && css`
    color: ${props => props.theme[props.scoreColor]};
  `}

  &:nth-child(even) {
    border-right: 1px solid;
    border-left: 1px solid;
    border-color: ${props => props.theme.primaryLightest};
  }

  &:nth-child(odd) {
    padding-left: 1px;
    padding-right: 1px;
  }

  &:last-child {
    border-right: none;
    padding-right: 1px;
  }
`;

export const ScoreInput = (props) => (
  <InputContainer>
    <ScoreIn {...props} />
  </InputContainer>
)

const InputContainer = styled.div`
  flex: 1 1 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;

  &:nth-child(even) {
    border-right: 1px solid;
    border-left: 1px solid;
    border-color: ${props => props.theme.primaryLightest};
  }

  &:nth-child(odd) {
    padding-left: 1px;
    padding-right: 1px;
  }

  &:last-child {
    border-right: none;
    padding-right: 1px;
  }
`;

const ScoreIn = styled.input`
  width: 100%;
  text-align: center;
  border: none;
  outline: none;
  padding: 0;
  font-size: 1rem;

  color: ${props => props.theme[props.scoreColor]};
`;

const NoResultText = styled(mdBody)`
  text-align: center;
  color: ${props => props.theme.secondaryText};
`;

export const NoResult = (props) => (
    <NoResultText {...props}>
      Inga resultat ännu
    </NoResultText>
);
