import React from "react";
import { Editor } from "react-draft-wysiwyg";
import LabeledInput from "../../components/LabeledInput";
import { Container } from "./styled";
import Button from "../../components/button";
import { EditorState, convertToRaw } from "draft-js";
import { connect } from "react-redux";
import { addArticle } from "../../redux/articles/actions";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

class NewPost extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      body: EditorState.createEmpty(),
      author: "",
    };
  }

  onSubmit() {
    const { title, body, author } = this.state;
    const { addPost } = this.props;

    addPost({
      title,
      author,
      body: JSON.stringify(convertToRaw(body.getCurrentContent())),
    });
  }

  render() {
    const { title, body, author } = this.state;

    return (
      <Container>
        <div>
          <LabeledInput
            label="Titel"
            value={title}
            onChange={(e) => this.setState({ title: e.target.value })}
          />
        </div>
        <div>
          <Editor
            toolbar={{
              options: ["inline"],
              inline: {
                options: ["bold", "italic", "underline", "strikethrough"],
              },
            }}
            editorState={body}
            wrapperClassName="post-editor-wrapper"
            editorClassName="post-editor"
            toolbarClassName="post-editor-toolbar"
            onEditorStateChange={(newState) =>
              this.setState({ body: newState })
            }
          />
        </div>
        <div>
          <LabeledInput
            label="Namn"
            value={author}
            onChange={(e) => this.setState({ author: e.target.value })}
          />
        </div>
        <div>
          <Button onClick={() => this.onSubmit()} text="Lägg till" />
        </div>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addPost: (data) => {
    dispatch(addArticle(data));
  },
});

export default connect(null, mapDispatchToProps)(NewPost);
