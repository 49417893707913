import axios from 'axios';
import apiService from 'services/api';

export const ADD_COURSE = 'ADD_COURSE';
export const ADD_COURSE_SUCCESS = 'ADD_COURSE_SUCCESS';
export const ADD_COURSE_FAILED = 'ADD_COURSE_FAILED';
export const UPDATE_NEW_COURSE = 'UPDATE_NEW_COURSE';
export const UPDATE_NEW_COURSE_HOLE = 'UPDATE_NEW_COURSE_HOLE';
export const UPDATE_NEW_COURSE_TEE = 'UPDATE_NEW_COURSE_TEE';
export const GET_COURSES = 'get_courses';
export const GET_COURSES_OK = 'get_courses_ok';
export const GET_COURSES_FAIL = 'get_courses_fail';

export const getCourses = () => dispatch => {
  dispatch({
    type: GET_COURSES
  });

  apiService.getCourses().then(res => {
    dispatch({
      type: GET_COURSES_OK,
      courses: res.data
    });
  }).catch(() => {
    dispatch({
      type: GET_COURSES_FAIL
    });
  })
}

export function addCourse() {
  return {
    type: ADD_COURSE
  }
}

export function addCourseSuccess(message) {
  return {
    type: ADD_COURSE_SUCCESS,
    message
  }
}

export function addCourseFailed(message) {
  return {
    type: ADD_COURSE_FAILED,
    message
  }
}

export function updateNewCourse(data) {
  return {
    type: UPDATE_NEW_COURSE,
    data
  }
}

export function updateNewCourseHole(data) {
  return {
    type: UPDATE_NEW_COURSE_HOLE,
    data
  }
}

export function updateNewCourseTee(data) {
  return {
    type: UPDATE_NEW_COURSE_TEE,
    data
  }
}

export const newCourse = () => (dispatch, getState) => {
  const { courses } = getState();
  dispatch(addCourse());

  axios.post(
    'http://192.168.100.182:5000/api/course',
    { ...courses.course }
  ).then(res => {
    return dispatch(addCourseSuccess('Course added!'));
  }).catch(err => {
    return dispatch(addCourseFailed('Could not add course!'));
  });
}
