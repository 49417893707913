import React from 'react';
import styled from 'styled-components';
import { media } from 'styles/utils/media';
import { Cross } from 'styled-icons/icomoon';
import { TileContainer } from 'styles/TileStyles';

export const Container = styled.div.attrs(props => ({
  alignItems: props.isLoading ? "center" : "default",
  justifyContent: props.isLoading ? "center" : "default"
}))`
  display: flex;
  width: 96%;

  flex-basis: auto;
  flex-grow: 1;
  flex-direction: column;
  align-self: center;

  align-items: ${props => props.alignItems};
  justify-content: ${props => props.justifyContent};

  @media ${media.laptop} {
    width: 80%;
  }
`;

export const NewUserForm = styled(TileContainer)`
  display: flex;
  position: relative;
`;

const StyledButton = styled.button`
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;
  padding: 0;
  border: none;
  outline: none;
  line-height: 0;

  &:active {
    color: ${props => props.theme.secondary};
  }
`;

const StyledExit = styled(Cross)`
  height: 0.7rem;
  width: 0.8rem;
  margin: 0;
`;

export const DeleteButton = (props) => (
  <StyledButton onClick={props.onClick}>
    <StyledExit />
  </StyledButton>
)

