import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.6rem;
  flex: 1 0 auto;
`;

export const Label = styled.span`
  font-family: Work Sans, sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: ${props => props.theme.primaryHeaderText};
  margin-bottom: 0.1rem;
`;
