import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, withRouter } from "react-router-dom";

import { isLoggedIn, isAdmin } from "redux/auth/selectors";

import Home from "../Home/Home";
import Results from "../Results/Results";
import Leaderboard from "../Results/Leaderboard";
import Schedule from "../Schedule/Schedule";
import PrivateRoute from "components/PrivateRoute";
import AddUsers from "../Users/Add";
import Profile from "../Users/Profile";
import NewPost from "../NewPost/index";

import { Container } from "./styled";

const mapStateToProps = (state) => {
  return {
    isLoggedIn: isLoggedIn(state),
    isAdmin: isAdmin(state),
  };
};

class Content extends Component {
  render() {
    return (
      <Container>
        <Switch>
          <PrivateRoute
            path="/add_users"
            isLoggedIn={this.props.isLoggedIn}
            component={AddUsers}
          />
          <PrivateRoute
            exact
            path="/"
            isLoggedIn={this.props.isLoggedIn}
            component={Home}
          />
          <PrivateRoute
            path="/leaderboard"
            isLoggedIn={this.props.isLoggedIn}
            component={Leaderboard}
          />
          <PrivateRoute
            path="/result"
            isLoggedIn={this.props.isLoggedIn}
            isEdit={false}
            component={Results}
          />
          <PrivateRoute
            path="/profile"
            isLoggedIn={this.props.isLoggedIn}
            isEdit={false}
            component={Profile}
          />
          <PrivateRoute
            path="/new_result"
            isLoggedIn={this.props.isLoggedIn}
            isEdit={this.props.isAdmin}
            component={Results}
          />
          <PrivateRoute
            path="/schedule"
            isLoggedIn={this.props.isLoggedIn}
            isEdit={false}
            component={Schedule}
          />
          <PrivateRoute
            path="/new_schedule"
            isLoggedIn={this.props.isLoggedIn}
            isEdit={this.props.isAdmin}
            component={Schedule}
          />
          <PrivateRoute
            path="/new_post"
            isLoggedIn={this.props.isLoggedIn}
            component={NewPost}
          />
        </Switch>
      </Container>
    );
  }
}

export default withRouter(connect(mapStateToProps, null)(Content));
