import React from "react";
import styled from "styled-components";
import { mdBody } from "styles/TextStyles";
import draftToHtml from "draftjs-to-html";

const Body = styled.div`
  width: 100%;
`;

const Text = styled(mdBody)`
  color: ${(props) => props.theme.primaryText};
`;

const ArticleBody = (props) => (
  <Body>
    <Text
      dangerouslySetInnerHTML={{
        __html: draftToHtml(JSON.parse(props.text)).replace(
          /<p><\/p>/g,
          "<br/>"
        ),
      }}
    />
  </Body>
);

export default ArticleBody;
