import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  width: 90%;

  border-radius: 0.3rem;
  box-shadow: 0px 5px 26px -5px ${props => props.theme.primaryDark};
  background: ${props => props.theme.appWhite};
  color: ${props => props.theme.primaryText};
`;

export const FormTitle = styled.div`
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  margin: 1rem 0;
`;

export const BtnRow = styled.div`
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  padding-bottom: 0.3rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
`;