import React from "react";
import styled from "styled-components";

import Title from "./title";
import Footer from "./footer";
import Body from "./body";

const ArticleCard = styled.div`
  /* border: 0.05rem solid ${(props) => props.theme.mainBorder}; */
  margin-bottom: 0.5rem;
  /* border-radius: 0.2rem; */
  padding: 0.3rem;

  background: ${(props) => props.theme.appWhite};
`;

const Article = (props) => (
  <ArticleCard>
    <Title
      text={props.article.title}
      isAdmin={props.isAdmin}
      onDelete={props.onDelete}
    />
    <Body text={props.article.body} />
    <Footer name={props.article.author} date={props.article.updatedAt} />
  </ArticleCard>
);

export default Article;
