import styled from 'styled-components';
import { mdCaption, styledH4 } from 'styles/TextStyles';

export const DataRow = styled.div`
  display: flex;
`;

export const DataItem = styled.div`
  flex: 1 1 0;
  height: 5rem;
  background: ${props => props.theme.appWhite};
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:first-child {
    margin-right: 0.3rem;
  }

  &:last-child {
    margin-left: 0.3rem;
  }
`;

export const DataHeader = styled(mdCaption)`
  margin: 0;
  text-align: center;
  color: ${props => props.theme.secondaryText};
`;

export const DataText = styled(styledH4)`
  margin: 0;
  text-align: center;
`;