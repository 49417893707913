import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Container } from './styled';
import LeaderList from 'components/leaderboard';

import { getLeaderboard } from 'redux/results/actions';
import { getLeaderIds, getLeaders, isFetchingLeaders } from 'redux/results/selectors';

const mapStateToProps = state => ({
  leaderIds: getLeaderIds(state),
  leaders: getLeaders(state),
  loading: isFetchingLeaders(state)
});

const mapDispatchToProps = dispatch => ({
  getLeaderboard: () => {
    dispatch(getLeaderboard());
  }
});

class Leaderboard extends Component {
  componentDidMount() {
    this.props.getLeaderboard();
  }

  render() {
    const { leaderIds, leaders } = this.props;

    return (
      <Container>
        { !this.props.loading ? <LeaderList leaderIds={leaderIds} leaders={leaders} /> : "" }
      </Container>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Leaderboard);