export const lightTheme = {
  primaryLightest: "#B4C1CB",
  primaryLight: "#4A6572",
  primary: "#344955",
  primaryDark: "#232F34",

  appWhite: "#FEFEFE",

  primaryHeaderText: "#18262A",
  primaryText: "#263840",
  secondaryText: "#767676",

  secondary: "#F9AA33",

  background: "#EDF0F2",
  error: "#f94545",
  success: "#42f489",

  overPar: "#0707b7",
  underPar: "#8e0101",
  even: "#263840"
}