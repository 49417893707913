
export const SET_ACTIVE_TAB = 'SET_TAB_FOCUS';
export const TOGGLE_MENU = 'TOGGLE_MENU';

export function setActiveTab(tab) {
  return {
    type: SET_ACTIVE_TAB,
    tab
  }
}

export function toggleMenu() {
  return {
    type: TOGGLE_MENU
  }
}
