import React from 'react';
import styled, { css } from 'styled-components';
import { mdButton } from 'styles/TextStyles';

const StyledButton = styled(mdButton)`
  color: ${props => props.theme.appWhite};
  flex: 1 0 auto;
  height: 2.2rem;

  border-radius: 0.2rem;
  background: ${props => props.theme.primary};
  border: none;
  outline: none;

  margin: 1rem 0.3rem 0.5rem 0.3rem;

  &:active {
    background: ${props => props.theme.secondary};
  }

  ${props => props.isCancel && css`
    background: ${props => props.theme.primaryLightest};
  `}
`;

const Button = (props) => (
  <StyledButton onClick={props.onClick} isCancel={props.isCancel}>{props.text}</StyledButton>
)

export default Button;