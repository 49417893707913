import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  isFetching,
  isError,
  isSuccess,
  getResults,
  startWeek,
  endWeek,
  lastWeek,
  currentWeek,
  getPlayerIds,
  statusText
} from 'redux/results/selectors';
import { courses } from 'redux/courses/selectors';
import { getCourses } from 'redux/courses/actions';
import { getResult, getRanges, updateScore, addResult, getWinners, sortResults, deleteRound } from 'redux/results/actions';

import Spinner from 'components/spinner/spinner';
import { Container } from './styled';
import WeekCarousel from 'components/results/weekCarousel';
import ResultList from 'components/results/resultList';
import Modal from 'components/confirmModal';
import Footer from 'components/footer';
import Button from 'components/button';

const mapStateToProps = state => ({
  results: getResults(state),
  fetching: isFetching(state),
  error: isError(state),
  success: isSuccess(state),
  currentWeek: currentWeek(state),
  startWeek: startWeek(state),
  lastWeek: lastWeek(state),
  endWeek: endWeek(state),
  courses: courses(state),
  playerIds: getPlayerIds(state),
  statusText: statusText(state)
});

const mapDispatchToProps = dispatch => ({
  getResult: (nr, edit, force) => {
    dispatch(getResult(nr, edit, force));
  },
  getRanges: () => {
    dispatch(getRanges());
  },
  getCourses: () => {
    dispatch(getCourses());
  },
  updateScore: (id, hole, value) => {
    dispatch(updateScore(id, hole, value));
  },
  addResult: () => {
    dispatch(addResult());
  },
  getWinners: () => {
    dispatch(getWinners());
  },
  sortResults: sort => {
    dispatch(sortResults(sort));
  },
  deleteRound: id => {
    dispatch(deleteRound(id));
  }
})

class Results extends Component {
  constructor(props) {
    super(props);

    this.state = {
      played_only: false,
      isModalOpen: false,
      id: null
    };
  }

  componentDidMount() {
    let nr = this.props.isEdit ? Math.min(this.props.lastWeek + 1, this.props.endWeek) : this.props.lastWeek;
    
    this.props.getCourses();
    this.props.getRanges();
    this.props.getResult(nr, this.props.isEdit);

    if (this.props.isEdit) {
      this.props.sortResults('alpha');
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isEdit !== prevProps.isEdit) {
      let nr = this.props.isEdit ? Math.min(this.props.lastWeek + 1, this.props.endWeek) : this.props.lastWeek;
      
      this.props.getResult(nr, this.props.isEdit, true);

      if (this.props.isEdit) {
        this.props.sortResults('alpha');
      } else {
        this.props.sortResults('score');
      }
    }
  }

  handleWeekChange = (dir) => event => {
    this.props.getResult(this.props.currentWeek + dir, this.props.isEdit);
  }

  handleInput = () => (event) => {
    const id = event.target.name.split('_')[0],
          nr = event.target.name.split('_')[1];

    this.props.updateScore(id, nr, parseInt(event.target.value, 10 ));
  }

  handleAdd = () => event => {
    this.props.getWinners();
    this.props.addResult();
  }

  handleHide = () => event => {
    this.props.getWinners();

    if (!this.state.played_only) {
      this.props.sortResults('played')
    } else {
      this.props.sortResults('alpha')
    }

    this.setState((state) => {
      return { played_only: !state.played_only };
    });
  }

  delRound = id => {
    this.props.deleteRound(id)
  }

  showConfirm = (id) => (event) => {
    this.setState((state) => {
      return { isModalOpen: !state.isModalOpen, id: id };
    });
  }

  modalCallback = (isCancel) => (event) => {
    if (!isCancel) {
      this.delRound(this.state.id);
    }

    this.setState((state) => {
      return { isModalOpen: !state.isModalOpen, id: null };
    });
  }

  getSpinner() {
    return (
      <Spinner />
    )
  }

  render() {
    const { currentWeek, courses, results, isFetching, success, error, fetching, statusText } = this.props;

    var courseName = '',
        teeName = '';

    if (results && courses[results.course]) {
      courseName = courses[results.course].name;
      teeName = courses[results.course].tees[results.tee].name;
    }

    return (
      <Container isLoading={isFetching}>
        {!isFetching ? <WeekCarousel 
          weekNr={currentWeek}
          courseName={courseName}
          tee={teeName}
          nextWeek={this.handleWeekChange(1)}
          prevWeek={this.handleWeekChange(-1)}
          hideNext={this.props.isEdit ? currentWeek === this.props.endWeek : currentWeek === this.props.lastWeek}
          hidePrev={currentWeek === this.props.startWeek}
          isLoading={isFetching}
         /> : "" }
        {!isFetching ? <ResultList 
          isEdit={this.props.isEdit}
          players={results ? results.players : {}}
          winners={results ? results.winners : {}}
          onDelete={this.showConfirm}
          showPoints={!this.props.isEdit || this.state.played_only}
          playerIds={results ? this.props.playerIds : []}
          holes={results ? courses[results.course].holes : {}}
          onChange={this.handleInput()} 
        /> : '' }
        { this.props.isEdit ? 
          <React.Fragment>
            <Modal onConfirm={this.modalCallback(false)} onCancel={this.modalCallback(true)} isOpen={this.state.isModalOpen} />
            <Footer onClick={this.handleAdd()} isError={error} isSuccess={success} isLoading={fetching} statusText={statusText}>
              <Button onClick={this.handleHide()} text={this.state.played_only ? 'Visa alla' : 'Sortera'} isCancel={true} />
            </Footer>
          </React.Fragment> : ""}
      </Container>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Results);