import {
  NEW_USER,
  UPDATE_USER,
  GET_USERS,
  GET_USERS_OK,
  GET_USERS_FAIL,
  DELETE_USER,
  DELETE_USER_OK,
  DELETE_USER_FAIL,
  ADD_USERS,
  ADD_USERS_OK,
  ADD_USERS_FAIL,
  GET_PROFILE,
  GET_PROFILE_FAIL,
  GET_PROFILE_OK
} from './actions';

const initialState = {
  users: {},
  usersById: [],
  profile: {},
  _nextUserId: 0,
  _isError: false,
  _isFetching: false,
  _isSuccess: false,
  _isLoading: false,
  _profileUpdated: 0,
  _fetchingProfile: false,
  _statusText: ""
}

function users(state = initialState, action) {
  switch(action.type) {
    case NEW_USER:
      return {
        ...state,
        _nextUserId: state._nextUserId + 1,
        users: {
          ...state.users,
          [action.id]: {
            email: "",
            firstname: "",
            lastname: "",
            hcp: 0,
            id: null
          }
        },
        usersById: [ ...state.usersById, action.id ]
      }
    case UPDATE_USER:
      return {
        ...state,
        users: {
          ...state.users,
          [action.id]: {
            ...state.users[action.id],
            [action.field]: action.data
          }
        }
      }
    case GET_USERS:
      return {
        ...state,
        _isFetching: true
      }
    case GET_USERS_OK:
      let ids = [];

      for (var player in action.players) {
        ids.push(player)
      }

      return {
        ...state,
        users: action.players,
        usersById: ids,
        _isFetching: false
      }
    case GET_USERS_FAIL:
      return {
        ...state,
        _isError: true,
        _isSuccess: false,
        _isFetching: false,
        _statusText: "Kunde inte hämta användare"
      }
    case DELETE_USER_OK: 
      const { [action.id]: deletedValue, ...newState_users } = state.users;

      return {
        ...state,
        usersById: state.usersById.filter(item => item !== action.id),
        users: newState_users,
        _isSuccess: true,
        _isError: false,
        _isLoading: false,
        _statusText: "Användare borttagen"
      }
    case DELETE_USER_FAIL:
      return {
        ...state,
        _isLoading: false,
        _isError: true,
        _isSuccess: false,
        _statusText: "Kunde inte ta bort användaren"
      }
    case DELETE_USER:
    case ADD_USERS:
      return {
        ...state,
        _isLoading: true,
        _isAddError: false,
        _isAddSuccess: false,
        _statusText: ""
      }
    case ADD_USERS_OK:
      return {
        ...state,
        _isLoading: false,
        _isError: false,
        _isSuccess: true,
        _statusText: "Användare sparade"
      }
    case ADD_USERS_FAIL:
      return {
        ...state,
        _isLoading: false,
        _isError: true,
        _isSuccess: false,
        _statusText: "Kunde inte spara användare"
      }
    case GET_PROFILE:
      return {
        ...state,
        _fetchingProfile: true,
        _isError: false,
        _isSuccess: false
      }
    case GET_PROFILE_OK:
      return {
        ...state,
        profile: action.player,
        _fetchingProfile: false,
        _isSuccess: true,
        _isError: false,
        _profileUpdated: Date.now()
      }
    case GET_PROFILE_FAIL:
      return {
        ...state,
        _fetchingProfile: false,
        _isSuccess: false,
        _isError: true
      }
    default:
      return state;
  }
}

export default users;