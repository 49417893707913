import React from 'react';
import InputBase from 'baseComponents/InputBase';
import { Container, Label } from './style';


const LabeledInput = ({ label, ...rest }) => (
  <Container>
    <Label>{label}</Label>
    <InputBase { ...rest } />
  </Container>
)

export default LabeledInput;