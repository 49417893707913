import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Comp, isLoggedIn, path, ...rest }) => (
  <Route
    path={path}
    { ...rest }
    render={routeProps => {
      return isLoggedIn ? (
        <Comp {...routeProps} {...rest} />
      ) : (
        <Redirect to={{
            pathname: "/login", 
            state: { 
              from: routeProps.location
            }
          }} 
        />
      )
    }}
  />
)

export default PrivateRoute;