import React from 'react';
import styled from 'styled-components';

const Input = styled.input`
  font-family: Work Sans, sans-serif;
  border: 1px solid lightgray;
  border-radius: 0.2rem;
  padding: 0.3rem 0.5rem;
  font-size: 1rem;
  outline: none;
  color: ${props => props.theme.primaryText};
`;

const InputBase = props => (
  <Input { ...props } />
)

export default InputBase;