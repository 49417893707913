import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, NewUserForm, DeleteButton } from './styled';
import InputWithLabel from 'components/inputWithLabel';
import CircularButton from 'components/circularButton';
import Modal from 'components/confirmModal';
import Footer from 'components/footer';
import Spinner from 'components/spinner/spinner';

import { getUsers, getNextId, getUsersId, isLoading, isError, isSuccess, getStatusText, isFetching } from 'redux/users/selectors';
import { newUser, updateUser, getAllUsers, deleteUser, addUsers } from 'redux/users/actions';

const mapStateToProps = state => ({
  users: getUsers(state),
  nextId: getNextId(state),
  usersById: getUsersId(state),
  isSuccess: isSuccess(state),
  isError: isError(state),
  isLoading: isLoading(state),
  statusText: getStatusText(state),
  isFetching: isFetching(state)
});

const mapDispatchToProps = dispatch => ({
  newUser: (id) => {
    dispatch(newUser(id));
  },
  updateUser: (id, field, data) => {
    dispatch(updateUser(id, field, data));
  },
  getAllUsers: () => {
    dispatch(getAllUsers());
  },
  deleteUser: (id) => {
    dispatch(deleteUser(id));
  },
  addUsers: () => {
    dispatch(addUsers());
  }
});

class AddUsers extends Component {
  constructor(props) {
    super(props);

    this.state = { isModalOpen: false, id: null };
  }

  componentDidMount() {
    this.props.getAllUsers();
  }

  handleInput = (id, type) => (event) => {
    this.props.updateUser(id, type, event.target.value);
  }

  addNewUser() {
    this.props.newUser(this.props.nextId);
  }

  delUser = (id) => { 
    this.props.deleteUser(id);
  }

  showConfirm = (id) => (event) => {
    this.setState((state) => {
      return { isModalOpen: !state.isModalOpen, id: id };
    });
  }

  modalCallback = (isCancel) => (event) => {
    if (!isCancel) {
      this.delUser(this.state.id);
    }

    this.setState((state) => {
      return { isModalOpen: !state.isModalOpen, id: null };
    });
  }

  createTile(id) {
    return (
      <NewUserForm key={id}>
        <InputWithLabel type="text" label="Förnamn" onChange={this.handleInput(id, "firstname")} value={this.props.users[id]["firstname"]}/>
        <InputWithLabel type="text" label="Efternamn" onChange={this.handleInput(id, "lastname")} value={this.props.users[id]["lastname"]}/>
        <InputWithLabel type="email" label="Email" onChange={this.handleInput(id, "email")} value={this.props.users[id]["email"]}/>
        <InputWithLabel type="number" label="Hcp" onChange={this.handleInput(id, "hcp")} value={this.props.users[id]["hcp"]}/>
        <DeleteButton onClick={this.showConfirm(id)} />
      </NewUserForm>
    )
  }

  createForms() {
    return (
      <React.Fragment>
          {
            this.props.usersById.map(id => {
              return this.createTile(id);
            })
          }
        <CircularButton onClick={this.addNewUser.bind(this)} />
      </React.Fragment>
    )
  }

  render() {
    const { isError, isSuccess, isLoading, statusText } = this.props;

    return (
      <Container isLoading={this.props.isFetching}>
        {
          this.props.isFetching ?
            <Spinner /> :
            this.createForms()
        }
        <Modal onConfirm={this.modalCallback(false)} onCancel={this.modalCallback(true)} isOpen={this.state.isModalOpen} />
        <Footer onClick={this.props.addUsers} isError={isError} isSuccess={isSuccess} isLoading={isLoading} statusText={statusText}/>
      </Container>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddUsers);