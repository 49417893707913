import React from 'react';
import styled from 'styled-components';
import { Plus } from 'styled-icons/boxicons-regular';
import { media } from 'styles/utils/media';

const StyledButton = styled.button`
  color: ${props => props.theme.primaryTextHeader};

  height: 2.5rem;
  width: 2.5rem;

  border-radius: 50%;
  background: ${props => props.theme.primaryLightest};
  border: none;
  outline: 0;

  margin-top: 0.2rem;
  margin-bottom: 1rem;

  &:active {
    background: ${props => props.theme.secondary};
  }

  align-self: center;

  @media ${media.laptop} {
    height: 3.5rem;
    width: 3.5rem;
  }
`;

const StyledPlus = styled(Plus)`
  height: 1.5rem;
  width: 1.5rem;

  @media ${media.laptop} {
    height: 2.5rem;
    width: 2.5rem;
  }
`;

const CircularButton = (props) => (
  <StyledButton onClick={props.onClick}>
    <StyledPlus />
  </StyledButton>
)

export default CircularButton;

