export const getUsers = state => state.users.users;
export const getUsersId = state => {
  return  state.users.usersById.sort((a, b) => {
    if (state.users.users[a].lastname < state.users.users[b].lastname) {
      return -1;
    } else if (state.users.users[a].lastname > state.users.users[b].lastname) {
      return 1;
    }

    return 0;
  });
};
export const getNextId = state => state.users._nextUserId;
export const isError = state => state.users._isError;
export const isLoading = state => state.users._isLoading;
export const isSuccess = state => state.users._isSuccess;
export const getStatusText = state => state.users._statusText;
export const isFetching = state => state.users._isFetching;
export const profile = state => state.users.profile;
export const fetchingProfile = state => state.users._fetchingProfile;