import axios from "axios";
import authService from "services/auth";

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

class Api {
  getTokenHeaders() {
    return {
      headers: {
        Authorization: "Bearer " + authService.getAccessToken(),
      },
    };
  }

  getArticles() {
    return this._getRequest("/articles");
  }

  addArticle(data) {
    return this._postRequest("/article", data);
  }

  deleteArticle(id) {
    return this._delRequest("/article/" + id);
  }

  getCourses() {
    return this._getRequest("/courses");
  }

  getSchedule(year) {
    return this._getRequest("/schedule/" + year);
  }

  getCoursesForSchedule() {
    return this._getRequest("/schedule/courses");
  }

  deleteWeek(id) {
    return this._delRequest("/week/" + id);
  }

  addSchedule(data) {
    return this._postRequest("/schedule", data);
  }

  getUsers() {
    return this._getRequest("/users");
  }

  getPlayers() {
    return this._getRequest("/players");
  }

  addPlayers(data) {
    return this._postRequest("/users", data);
  }

  deleteUser(id) {
    return this._delRequest("/users/" + id);
  }

  getResults(nr) {
    return this._getRequest("/result/" + nr);
  }

  getWeekRanges() {
    return this._getRequest("/result_ranges");
  }

  getLeaderboard() {
    return this._getRequest("/leaderboard");
  }

  getProfile() {
    return this._getRequest("/profile");
  }

  addResult(nr, data) {
    return this._postRequest("/result/" + nr, data);
  }

  deleteRound(id) {
    return this._delRequest("/result/" + id);
  }

  _getRequest(url) {
    return axios.get(BASE_URL + url, this.getTokenHeaders());
  }

  _postRequest(url, data) {
    return axios.post(BASE_URL + url, data, this.getTokenHeaders());
  }

  _delRequest(url) {
    return axios.delete(BASE_URL + url, this.getTokenHeaders());
  }
}

const apiService = new Api();

export default apiService;
