import React from 'react';
import styled, { css } from 'styled-components';

import { mdSubtitle2 } from 'styles/TextStyles';

const Container = styled.div`
  display: flex;
  border-radius: 0.2rem;
  border: 1px solid ${props => props.theme.error};

  background: rgba(249, 69, 69, 0.3);

  margin: 0.5rem;
  padding: 0.4rem 0.5rem;

  ${props => props.isSuccess && css`
    border-color: ${props => props.theme.success};
    background: rgba(66, 244, 137, 0.3);
  `} 
`;

const Message = styled(mdSubtitle2)`
  margin: 0;
  color: ${props => props.theme.error};

  ${props => props.isSuccess && css`
    color: ${props => props.theme.success};
  `}
`;

export const ErrorBox = (props) => (
  <Container>
    <Message>{ props.desc }</Message>
  </Container>
)

export const SuccessBox = (props) => (
  <Container isSuccess={true}>
    <Message isSuccess={true}>{ props.desc }</Message>
  </Container>
)
