import apiService from "services/api";

export const REQ_ARTICLES = "REQUEST_ARTICLES";
export const REC_ARTICLES = "RECEIVE_ARTICLES";
export const REC_ARTICLES_FAILED = "RECEIVE_ARTICLES_FAILED";
export const ADD_ARTICLE = "ADD_ARTICLE";
export const ADD_ARTICLE_OK = "ADD_ARTICLE_OK";
export const ADD_ARTICLE_ERROR = "ADD_ARTICLE_ERROR";
export const REMOVE_ARTICLE = "REMOVE_ARTICLE";
export const REMOVE_ARTICLE_OK = "REMOVE_ARTICLE_OK";
export const REMOVE_ARTICLE_ERROR = "REMOVE_ARTICLE_ERROR";

export function reqArticles() {
  return {
    type: REQ_ARTICLES,
  };
}

export function recArticles(articles) {
  return {
    type: REC_ARTICLES,
    articles,
  };
}

export function recArticlesFailed() {
  return {
    type: REC_ARTICLES_FAILED,
  };
}

export const getArticles = () => (dispatch, getState) => {
  const lastFetched = getState().articles._lastUpdated;

  // Hours since last update
  const isStale = Math.abs(Math.round((Date.now() - lastFetched) / 36e5)) >= 2;

  if (isStale) {
    dispatch(reqArticles());

    apiService
      .getArticles()
      .then((res) => {
        return dispatch(recArticles(res.data));
      })
      .catch((err) => {
        return dispatch(recArticlesFailed());
      });
  }
};

export const addArticle = (data) => (dispatch, getState) => {
  dispatch({ type: ADD_ARTICLE });

  apiService
    .addArticle(data)
    .then(() => {
      dispatch({ type: ADD_ARTICLE_OK });
    })
    .catch(() => {
      dispatch({ type: ADD_ARTICLE_ERROR });
    });
};

export const deleteArticle = (id) => (dispatch) => {
  dispatch({ type: REMOVE_ARTICLE });

  apiService
    .deleteArticle(id)
    .then(() => {
      dispatch({
        type: REMOVE_ARTICLE_OK,
        id,
      });
    })
    .catch(() => {
      dispatch({ type: REMOVE_ARTICLE_ERROR });
    });
};
