import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { parseHash } from 'redux/auth/actions';
import { isParsed } from 'redux/auth/selectors';
import Spinner from 'components/spinner/spinner';

const Container = styled.div`
  display: flex;
  width: 96%;

  flex-basis: auto;
  flex-grow: 1;
  flex-direction: column;
  align-self: center;

  align-items: center;
  justify-content: center;
`;

const mapDispatchToProps = dispatch => {
  return {
    parse: () => {
      dispatch(parseHash());
    }
  }
}

const mapStateToProps = state => {
  return {
    isParsed: isParsed(state)
  }
}

class AuthCallback extends Component {
  componentDidMount() {
    this.props.parse();
  }

  render () {
    return (
      <Container>
        <Spinner />
        { this.props.isParsed ? <Redirect to='/'/> : ""}
      </Container>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthCallback);